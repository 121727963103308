import Vue from 'vue'
import Vuex from 'vuex'
const fb = require('./../firebaseConfig.js')

Vue.use(Vuex)


// handle page reload
fb.auth.onAuthStateChanged(user => {
    if (user) {
        store.commit('setCurrentUser', user)
        store.dispatch('fetchUserProfile')

        // realtime updates from our clients collection
        fb.clientsCollection.onSnapshot(querySnapshot => {
            let clientArray = []

            querySnapshot.forEach(doc => {
                let client = doc.data()
                client.id = doc.id
                clientArray.push(client)
            })

            store.commit('setClients', clientArray)
        })

        // realtime updates from our clients collection
        fb.codesCollection.onSnapshot(querySnapshot => {
            let codesArray = []

            querySnapshot.forEach(doc => {
                let code = doc.data()
                code.id = doc.id
                codesArray.push(code)
            })

            store.commit('setCodes', codesArray)
        })
    }
})

export const store = new Vuex.Store({
    state: {
        currentUser: null,
        currentClientProfile: {},
        userProfile: {},
        clients: [],
        codes: []
    },
    actions: {
        clearData({ commit }) {
            commit('setCurrentUser', null)
            commit('setCurrentClientProfile', {})
            commit('setUserProfile', {})
            commit('setClients', null)
            commit('setCodes', null)
        },
        fetchUserProfile({ commit, state }) {
            fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
                commit('setUserProfile', res.data())
            }).catch(err => {
                console.log(err)
            })
        },
        fetchCurrentClientProfile({commit}, id ) {
            fb.clientsCollection.doc(id).get().then(res => {
                // console.log(res)
                commit('setCurrentClientProfile', res.data())
            }).catch(err => {
                console.log(err)
            })
        },
        updateProfile({ state }, data) {
            let name = data.name
            let company = data.company

            fb.usersCollection.doc(state.currentUser.uid).update({ name, company })
            .then(user => {
                console.log(user)
                // commit('setUserProfile', user)
            }).catch(err => {
                console.log(err)
            })
        }
    },
    mutations: {
        setCurrentUser(state, val) {
            state.currentUser = val
        },
        setCurrentClientProfile(state, val) {
            state.currentClientProfile = val
        },
        setUserProfile(state, val) {
            state.userProfile = val
        },
        setClients(state, val) {
			state.clients = val
        },
        setCodes(state, val) {
            state.codes = val
        }
    }
})
