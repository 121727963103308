<template>
    <div id="start">
        <div class="p-2 border-b border-gray-500">
            <router-link :to="{ name: 'Dashboard' }">Return to Dashboard</router-link>
        </div>
        <div class="bg-white border-b border-gray-500 p-8 md:px-32 flex justify-between items-center">
            <div>
                <h1 class="font-bold text-3xl">{{ currentClientProfile.company_name }}</h1>
            <p>{{ currentClientProfile.name }}</p>
            <p class="text-sm text-teal-600"><a :href="'https://start.nthround.com/id/' + id">start.nthround.com/id/{{ id }}</a></p>
            </div>
            
            <button @click="sendAuthLink" class='bg-gray-900 hover:bg-gray-700 text-white font-bold py-3 px-4 rounded mb-6 mt-4'>Send Auth Link</button>
        </div>
        <div class="">

            <div  @load="text = $event">

                <div class="hidden md:block pt-10 pb-20 md:px-32">
                    <div>
                        <h3 class="mb-8">Before uploading, make sure you cap table includes a nice ticker <b>SYMBOL</b> at the top of each class.</h3>
                        <p class="mb-8">
                            <label>
                                <input v-model="approveShares" type="checkbox" checked="checked"/>
                                <span> Set Approved Shares = Owned Shares? (Otherwise Approved Shares will be set to zero.)</span>
                            </label>
                        </p>
                        <p class="mb-8">
                            <label>
                                <input v-model="permitPurchase" type="checkbox" checked="checked"/>
                                <span> Set PermitPurchase = True? (Otherwise PermitPurchase will be set to False.)</span>
                            </label>
                        </p>
                        <p class="mb-8">
                            <label>
                                <input v-model="doCognito" type="checkbox" checked="checked"/>
                                <span> Add these new users to Cognito? (Only do this with <b>real</b> users that <i>actually</i> will authenticate.)</span>
                            </label>
                        </p>
                    </div>

                    <div v-if="!shareholders.length && !uploadError && !currentlyImporting" class="mb-4">
                        <vue-dropzone ref="myVueDropzone" v-on:vdropzone-file-added="loadFromFile" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                    </div>

                    <!-- <button class=''>Delete Record</button> -->

                    <div v-if="uploadError" class="border border-red-600 rounded p-8 bg-gray-100">
                        <h3 class="font-bold text-xl mb-2">We were unable to import your cap table automatically</h3>
                        <p class="mb-4 text-gray-700">Don't worry, our team is standing by to help. Click below to send your cap table directly to your Nth Round account manager and we'll get everything taken care of and send you a notification when your account is ready.</p> 

                        <button @click="requestSupport" class="border border-gray-900 hover:bg-gray-200 text-gray-900 font-bold py-2 px-4 rounded mb-4">Send to Support</button>
                        
                    </div>



                    <table v-if="shareholders.length && !currentlyImporting && !uploadError" class="bg-white mt-8 w-full">

                      <tr v-bind:key="item[0]" v-for="item in shareholders" class='text-sm'>
                        <td class="border px-4 py-2">{{ item[0] }}</td>
                        <td class="border px-4 py-2">{{ item[1] }}</td>
                        <td class="border px-4 py-2">{{ item[2] ? item[2] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[3] ? item[3] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[4] ? item[4] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[5] ? item[5] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[6] ? item[6] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[7] ? item[7] : '' }}</td>
                      </tr>
                    </table>

                    <div v-if="currentlyImporting" class="h-48 flex items-center justify-center">
                        <div v-if="!capTableUploaded">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="mx-auto">
                                <rect x="0" y="13" width="4" height="5" fill="#333">
                                  <animate attributeName="height" attributeType="XML"
                                    values="5;21;5"
                                    begin="0s" dur="0.6s" repeatCount="indefinite" />
                                  <animate attributeName="y" attributeType="XML"
                                    values="13; 5; 13"
                                    begin="0s" dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="10" y="13" width="4" height="5" fill="#333">
                                  <animate attributeName="height" attributeType="XML"
                                    values="5;21;5"
                                    begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                  <animate attributeName="y" attributeType="XML"
                                    values="13; 5; 13"
                                    begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="20" y="13" width="4" height="5" fill="#333">
                                  <animate attributeName="height" attributeType="XML"
                                    values="5;21;5"
                                    begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                  <animate attributeName="y" attributeType="XML"
                                    values="13; 5; 13"
                                    begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                </rect>
                            </svg>
                            <div class="text-gray-600 text-sm text-center mt-4">Importing shareholders...</div>
                        </div>
                        <div v-if="capTableUploaded" class="h-48 flex items-center justify-center">
                            <div class="pt-12 text-center">
                                <svg class="bg-teal-600 p-6 mb-8 rounded-full mx-auto text-center h-24 w-24 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                                <h1 class="text-3xl mb-4">Success!</h1>
                            </div>
                        </div>
                    </div>

                    

                    <div v-if="shareholders.length > 5 && !uploadError && !currentlyImporting" class="text-center text-gray-500 mt-2">Showing <b>{{ shareholders.length - 1}}</b> records. Some right-hand columns may be intentionally blank.</div>

                    <button @click="importShareholders(shareholders)" v-bind:class="[shareholders.length ? disabled : '']" v-if="shareholders.length && !uploadError && !currentlyImporting" class='bg-gray-900 hover:bg-gray-700 text-white w-full font-bold py-3 px-4 rounded mb-6 mt-4'>Import to Nth Round</button>

                    <!-- Display the errors here -->
                    <div v-if="badData.length" class="p-2 text-gray-700 bg-yellow-500 flex justify-between mt-8">
                        <div>We found <span class="font-bold">{{ badData.length - 1 }}</span> rows with errors.</div>
                        <!-- <button @click="showAllErrors">Show all</button> -->
                    </div>
                    <table v-if="badData.length" class="bg-white w-full mb-4">

                      <tr v-bind:key="item[0]" v-for="item in badData" class='text-sm'>
                        <td class="border px-4 py-2">{{ item[0] }}</td>
                        <td class="border px-4 py-2">{{ item[1] }}</td>
                        <td class="border px-4 py-2">{{ item[2] ? item[2] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[3] ? item[3] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[4] ? item[4] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[5] ? item[5] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[6] ? item[6] : '' }}</td>
                        <td class="border px-4 py-2">{{ item[7] ? item[7] : '' }}</td>
                      </tr>
                    </table>


                    <p class="text-gray-600" v-if="shareholders.length && !uploadError && !currentlyImporting">
                        <svg class="fill-current text-orange-400 inline-block h-4 w-4 mb-1 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                        To make changes and upload your cap table again <span @click="uploadError = false, shareholders = [], badData = []" class="text-teal-600 cursor-pointer">click here</span>. If something doesn't look quite right in the data please <span class="text-teal-600 cursor-pointer" @click="requestSupport">click here</span> to send your cap table to your account manager who will be happy to help.
                    </p>
                </div>

                <div class="block md:hidden px-8 text-center bg-gray-200 h-screen">
                    <img src="https://res.cloudinary.com/nthround/image/upload//h_48/v1576255169/nth-logo.svg" alt="Nth Round Logo" class="mx-auto w-24 pt-4 mb-16">
                    <h3 class="text-3xl font-bold mb-4">To finish setting up your Nth Round account go to your computer.</h3>
                    <p class="">We just sent you an email with a link to your Nth Round account. See you soon.</p>
                </div>
                
            </div>

            <!-- TODO: Potentially Add Admins (skippable) -->

            <!-- TODO: Potentially Add Transactions (skippable) -->
            
        </div>
        
    </div>
</template>

<script>
    // import firebase from 'firebase/compat/app';
    import { mapState } from 'vuex'
    import Papa from 'papaparse'
    import XLSX from 'xlsx'
    // import { stripeKey, stripeOptions } from './stripeConfig.js'
    // import { Card, createToken } from 'vue-stripe-elements-plus'
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import axios from 'axios'
    export default {
        created() {
            // If a client ID is specified in the url fetch the client profile from Firebase
            if (this.$route.params.id) {
                console.log(this.$route.params.id)
                this.$store.dispatch('fetchCurrentClientProfile', this.$route.params.id);
                
            }
        },
        // beforeUpdate () {
        //     console.log(this.$store.state["currentClientProfile"].token)
        //     if (this.$store.state["currentClientProfile"].token) {
        //         this.currentTask = 4
        //     }
        // },
        data() {
            return {
                id: this.$route.params.id,
                performingRequest: false,
                showCardError: false,
                complete: false,
                disabled: true,
                shareholders: [],
                badData: [],
                approveShares: true,
                permitPurchase: true,
                doCognito: true,
                uploadError: false,
                currentFile: null,
                currentlyImporting: false,
                capTableUploaded: false,
                userApi: 'https://oxx4gukklc.execute-api.us-east-1.amazonaws.com/presigned-exec/AQICAHjyZmzK8d2PGyyv1Zve93nUW1AyRl9VZtdIMbp8zlT_-QGNIk_itJ0ParpLeJiClnuLAAAA6TCB5gYJKoZIhvcNAQcGoIHYMIHVAgEAMIHPBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDLJN4EKzBbSlwPHNLgIBEICBoZx9i6PosKU9wp1GUNWZtgPYj2t11uVfyRrZ1RClNa0EqWiVFK41WLKBsGCTVjsESkDBoL6jYUZELqDPLi8jQQjXKs5Ml1RheGEqnMq1UMXKpuHTCvbOKaVlNP2NDx7FvD9miEpw6iQMpfzOSkIJZpgVaLQ9sSXQ_d2nkP9pa8wZH1AraLU0zGveE2-sc_FiLKLKxMDS-tYKST-LWoTU2dvK',
                dropzoneOptions: {
                    dictDefaultMessage: 'Please upload your cap table as a xlsx, xls, xml, csv, tsv, txt, or rtf file. <br/> <br/> Each record must include a Name, Email, and Amount of Equity.',
                    url: 'https://httpbin.org/post',
                    thumbnailWidth: 150,
                    maxFilesize: 0.5,
                    maxFiles: 1,
                    addRemoveLinks: true,
                    headers: { "My-Awesome-Header": "header value" }
                }
            }
        },
        components: { 
        //     Card,
            vueDropzone: vue2Dropzone
        },
        computed: {
            ...mapState(['userProfile', 'currentClientProfile'])
        },
        methods: {
            requestSupport: function () {
                let url = 'https://api.cloudinary.com/v1_1/nthround/auto/upload'
                let uploadPreset = 'ytkdysdh'
                let fd = new FormData()
                fd.append("upload_preset", uploadPreset)
                fd.append("file", this.currentFile)
                let axconf = {
                headers: { "X-Requested-With": "XMLHttpRequest" },
                resource_type: 'auto'
                };
                let _this = this
                axios.post(url, fd, axconf)
                .then(function(res){
                     console.log(res);
                     let fileToUpload = res.data.secure_url;
                     console.log(fileToUpload);
                     let url = 'https://hooks.zapier.com/hooks/catch/4626966/ouzdwtn/';
                     axios.get(url, {
                        params: {
                            client: _this.currentClientProfile.workspace,
                            email: _this.currentClientProfile.email,
                            name: _this.currentClientProfile.name,
                            file: fileToUpload
                        }
                    })
                    .then(function(res) {
                        console.log(res);
                    })
                    .catch(function(err) {
                        console.log(err);
                    })
                })
                .catch(function(err){
                 console.error(err);
                });
            },
            cleanPrefixes: function (sortedArray) {
              //Get rid of prefixes and capitalize first name
              let nameColumn = sortedArray.map(j => j[0]);
              let prefixRegex = /^(adm|atty|capt|chief|cmdr|col|dr|father|gen|gov|hon|mr|mrs|ms|prof|rev)\.?$/;
              for (let i = 1; i < nameColumn.length; i++) {
                let join = false;
                let names = nameColumn[i].split(' ');
                if (names.length > 1) {
                  if (names[0].trim().toLowerCase().match(prefixRegex)) {
                    names.shift();
                    if (names[0].trim().toLowerCase().match(/^(and|&)$/) && names[1].trim().toLowerCase().match(prefixRegex)) {
                      names.shift();
                      names.shift();
                    }
                join = true;
              }
              //Get rid of first initial
              if (names.length > 2 && names[0].match(/^\w\.?$/)) {
                names.shift();
                join = true;
                  }
              if (join) sortedArray[i][0] = names.join(' ').trim().replace('  ',' ');
                }
                sortedArray[i][0] = sortedArray[i][0].replace(/^\w/, c => c.toUpperCase());
              }
              // console.log(sortedArray);
              return sortedArray;
            },
            saveProfile () {
              // https://boz71b8oe5.execute-api.us-east-1.amazonaws.com/Prod/client/updateCompanyProfile?client=001test&company_name=TEST1&contact_email=contact@email.com&company_logo=anicelogo&product_description=ProductDescription
            },
            sendAuthLink () {
                let client = this.currentClientProfile.workspace;
                let userid = this.currentClientProfile.email.toLowerCase();
                userid = userid.trim();
                // let _this = this
                const authUrl = 'https://oxx4gukklc.execute-api.us-east-1.amazonaws.com/presigned-exec/AQICAHjyZmzK8d2PGyyv1Zve93nUW1AyRl9VZtdIMbp8zlT_-QGNIk_itJ0ParpLeJiClnuLAAAA6TCB5gYJKoZIhvcNAQcGoIHYMIHVAgEAMIHPBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDLJN4EKzBbSlwPHNLgIBEICBoZx9i6PosKU9wp1GUNWZtgPYj2t11uVfyRrZ1RClNa0EqWiVFK41WLKBsGCTVjsESkDBoL6jYUZELqDPLi8jQQjXKs5Ml1RheGEqnMq1UMXKpuHTCvbOKaVlNP2NDx7FvD9miEpw6iQMpfzOSkIJZpgVaLQ9sSXQ_d2nkP9pa8wZH1AraLU0zGveE2-sc_FiLKLKxMDS-tYKST-LWoTU2dvK?client=' + client + "&userid=" + encodeURIComponent(userid) + "&io.nthround.env=staging";

                axios.get(authUrl)
                   .then(res => {
                     console.log("response from authlink api: ", res);
                     console.log("Auth Link Sent");
                     //alert('Auth Link Sent')
                     // _this.incrementTask();
                   })
                   .catch(err => {
                     console.error(err);
                   })
            },
            importShareholders (shareholders) {
              // console.log(shareholders);

              // console.log('Share classes: ' + (shareholders[0].length - 2));
              let l = shareholders[0].length;
              let classes = shareholders[0].slice(2, l);
              let _this = this;
              console.log('Share classes: ', classes);
              this.currentlyImporting = true;
              shareholders.forEach(function (shareholder, index) {
                // Start after the header row
                if (index > 0) {
                    let sharesObj = {};
                    console.log(shareholder[1], shareholder.length);

                    classes.forEach(function (i, index) {
                        if (Number(shareholder[index + 2]) > 0) {
                            sharesObj[i] = {
                                owned: shareholder[index + 2],
                                approved: _this.approveShares ? shareholder[index + 2] : 0,
                                committed: "0",
                                permitPurchase: _this.permitPurchase ? true : false
                            }
                        }
                    });

                    console.log(sharesObj);

                    let shareholderObj = {
                        user_name: shareholder[0],
                        user_img: 'https://www.gravatar.com/avatar/952b80295294f41ee6612f062c878ab3?s=80&d=retro',
                        userid: shareholder[1],
                        'io.nthround.env': 'master',
                        ethereum_address: null,
                        clients: {
                            [_this.currentClientProfile.workspace]: {
                                investor: {
                                    complete: false,
                                    entity: false,
                                    income: false,
                                    netWorth: false,
                                    sophisticated: false
                                },
                                joinder: {
                                    complete: false
                                },
                                shares: sharesObj,
                                documents: [],
                                tags: []
                            }
                        }
                    }

                    console.log(shareholderObj);

                    let postURL = 'https://oxx4gukklc.execute-api.us-east-1.amazonaws.com/presigned-exec/AQICAHjyZmzK8d2PGyyv1Zve93nUW1AyRl9VZtdIMbp8zlT_-QHIC9v54ruZY8YouriDkWEiAAAA2TCB1gYJKoZIhvcNAQcGoIHIMIHFAgEAMIG_BgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDF5_0NPpGSrm-ugVYgIBEICBkVsofShMXmejxucWphcWsu4Br3c8fnkU6-qDdtamrkx778j2U02mpH2uzEFMo84Rai1xaFU2Wko00DwmOk_5IdVy6JMCoZfKfT3_QyZ1tAjVDf6y_kc-MTjT6q4uGlUYmFCTdivz34RoJ_gFsVpLtFZ0d0dLzT4OiqnJpxf1u0kbn6pTqi7pLnBaoh4CBLNPf5A=?io.nthround.env=master';
                    
                    axios.post(postURL, shareholderObj)
                        .then(function(res) {
                            console.log(res);
                            let userlistURL = 'https://boz71b8oe5.execute-api.us-east-1.amazonaws.com/Stage/client/addmin?client=' + _this.currentClientProfile.workspace + '&userid=' + _this.currentClientProfile.email + '&userlist=' + shareholderObj.userid;
                            axios.post(userlistURL)
                            .then(function(res){
                              console.log(res, 'Added: ' + shareholderObj.userid);

                              if (_this.doCognito) {
                                // Add user to Cognito
                                axios.get('https://api.nthround.io/portal/auth/add-user',{
                                  params: {
                                    client: _this.currentClientProfile.workspace,
                                    userid: shareholderObj.userid,
                                    user_img: shareholderObj.user_img,
                                    user_name: shareholderObj.user_name,
                                    admin: "False"
                                  },
                                  //withCredentials: true
                                })
                                .then(function (response) {
                                  console.log(response);
                                })
                                .catch(function (error) {
                                  console.log(error);
                                });
                              }
                            })
                            .catch(function(err){
                                console.error(err);
                                _this.uploadError = true;
                            })
                        })
                        .catch(function(err){
                            console.error(err);
                            _this.uploadError = true;
                        })                
                }
              });
              // this.sendAuthLink()
              this.capTableUploaded = true;
            },
            transformData (csv) {
              let results = Papa.parse(csv);
              // console.log(results);
              // Trim and shrink data
              let data = results.data;
              for (let i = 0; i < data.length; i++)
                for (let j = 0; j < data[i].length; j++)
                  data[i][j] = data[i][j].trim();
              let goodRows = data.filter(element => element.join("") != "");
              let goodCols = (goodRows[0] || []).map((c, i) => goodRows.some(a => a[i]));
              let array = goodRows.map(a => a.filter((_, i) => goodCols[i]));
              // console.log(array);
              // Size up array (assume rectangular array in CSV)
              // let verifyCount = 0;
              let rowCount = array.length;
              let midCount = Math.floor(rowCount/2);
              let colCount = array[midCount].length;
              // let checkCount = Math.floor(rowCount/20);
              let rowDataCount = new Array(rowCount).fill(0);
              // Set up json and regex
              let colData = {
                "name":{"cols":[colCount],"minRow": 999,"maxRow": 0},
                "email":{"cols":[colCount],"minRow": 999,"maxRow": 0},
                "number":{"cols":[colCount],"minRow": 999,"maxRow": 0}
                }
              let nameRegex = /\b[a-zA-Z ]+(?:([',. &()-][a-zA-Z ])?[a-zA-Z]*)*\b\)?/
              let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
              let numberRegex = /^(\d+(?:[.,]\d+)?(?:[.,]\d+)?\b(?!(?:[.,/]\d+)|(?:\s?%|\s?percent|\s?pct)))$/
              let wierdRegex = /[~!@#$%^&*()_+{}|:"<>?`=[\]\\;'/-]/
              // Count the instances in each column
              for (let j = 0; j < colCount; j++) {
                colData.name.cols[j] = 0;
                colData.email.cols[j] = 0;
                colData.number.cols[j] = 0;
                for (let i = 0; i < rowCount; i++) {
                    let text = array[i][j].trim();
                    let match = text.match(nameRegex);
                    if (match && match[0].length == text.length && !text.match(emailRegex) && text.length < 50) {
                    colData.name.cols[j]++;
                    if (j > 2 && j < colCount-2) console.log("Unexpected Name = " + text);
                    if (i < colData.name.minRow) colData.name.minRow = i;
                    if (i > colData.name.maxRow) colData.name.maxRow = i;
                  }
                  if (text.match(emailRegex)) {
                    colData.email.cols[j]++;
                    if (j == 0 || (j > 4 && j != colCount - 1)) console.log("Unexpected Email = " + text);
                    if (i < colData.email.minRow) colData.email.minRow = i;
                    if (i > colData.email.maxRow) colData.email.maxRow = i;
                  }
                match = text.match(numberRegex);
                if (match && match[0].length == text.length && Number(match[0].replace(/[^.\d]/g, '')) > 0 && !text.match(wierdRegex)) {
                    colData.number.cols[j]++;
                    if (j < 1) {
                        console.log("Unexpected Number = " + text);
                        this.uploadError = true;
                    }
                    if (i < colData.number.minRow) colData.number.minRow = i;
                    if (i > colData.number.maxRow) colData.number.maxRow = i;
                  }
                  if (text != "") rowDataCount[i]++;
                }
              }
              // console.log(colData);

              // If multiple name columns, concatenate
                let highCharCol = -1;
                let lastNameCol = -1;
                let firstNameCol = -1;
                let highCharCount = 0;
                let charCount = new Array(colCount).fill(0);
                let nameCutOff = Math.max(...colData.name.cols) / 2;
                for (let j = 0; j < colCount; j++) {
                    if (colData.name.cols[j] > nameCutOff) {
                        lastNameCol = j;
                        if (firstNameCol < 0) firstNameCol = j;
                        for (let i = 0; i < rowCount; i++) {
                            charCount[j] += array[i][j].trim().length;
                        }
                        if (charCount[j] > highCharCount) {
                            highCharCol = j;
                            highCharCount = charCount[j];
                        }
                    } else {
                        if (lastNameCol > 0) break;
                    }
                }
                if (firstNameCol < lastNameCol) {
                    let concats = lastNameCol - firstNameCol;
                    for (let k = 0; k < concats; k++) {
                        charCount[highCharCol] = 0;
                        let nextCharCol = charCount.indexOf(Math.max(...charCount));
                        for (let i = 0; i < rowCount; i++) {
                            array[i][nextCharCol] = (array[i][nextCharCol] + " " + array[i][highCharCol]).trim();
                        }
                        colData.name.cols[highCharCol] = 0;
                        highCharCol = nextCharCol;
                    }
                }

              //Identify columns and determine starting and finishing rows
              let nameCol = colData.name.cols.reduce((jMax, x, j, arr) => x > arr[jMax] ? j : jMax, 0);
              let emailCol = colData.email.cols.reduce((jMax, x, j, arr) => x > arr[jMax] ? j : jMax, 0);
              let nameCount = colData.name.cols[nameCol];
              let emailCount = colData.email.cols[emailCol];
              // let numberCount = Math.max(...colData.number.cols);
              let startRow = Math.max(colData.name.minRow,colData.number.minRow);
              let finishRow = Math.min(colData.name.maxRow,colData.number.maxRow);
              // handle no or screwy number columns
              if (startRow > finishRow) {
                startRow = colData.name.minRow;
                finishRow = colData.name.maxRow;
              }
              if (emailCount > nameCount / 2) {
                //use emails too
                startRow = Math.max(startRow,colData.email.minRow);
                finishRow = Math.min(finishRow,colData.email.maxRow);
              }
              let minDataCount = rowDataCount.reduce((a,b) => a + b, 0)  / rowDataCount.length / 3;
              let maxDataCount = minDataCount * 6;  //5;
              for (let i = startRow; i <= finishRow; i++) {
                if (i < (finishRow / 2) && (rowDataCount[i] < minDataCount || rowDataCount[i] > maxDataCount))
                  startRow = i + 1;
                if (i > (finishRow / 2) && (rowDataCount[i] < minDataCount || rowDataCount[i] > maxDataCount)) {
                  //if (array[i][nameCol] != "") {    // assumes contiguous columns of names ??
                  //  finishRow = i - 1;
                  //} else {  //handle row sub-headings and/or sub-totals
                    rowDataCount.splice(i,1);
                    array.splice(i,1);
                    finishRow--;
                    i++;
                  //}
                } else if (i > (finishRow * 0.8) && (JSON.stringify(array[i]).toLowerCase().indexOf("total") >= 0))
                  finishRow = i - 1;
              }
              //Score data to remove any numeric rows that correspond to subtotals and totals
              let MIN_NUM_COUNT = Math.min(4, finishRow - startRow);
              //MIN_NUM_COUNT = 0;
              let headings = new Array(colCount).fill("");
              let totals = new Array(colCount).fill(0);
              for (let j = 0; j < colCount; j++) {
                for (let i = 0; i < startRow; i++) {
                  headings[j] += array[i][j] + " ";
                }
                headings[j] = headings[j].replace(/ +(?= )|0 /g,' ').trim();
                for (let i = startRow; i <= finishRow; i++)
                  if (colData.number.cols[j] > MIN_NUM_COUNT && array[i][j].length > 0) {
                    if (!array[i][j].match(numberRegex)) {
                      array[i][j] = "";
                      continue;
                    }
                    array[i][j] = parseFloat(array[i][j].replace(/[^.\d]/g, ''));
                    totals[j] += array[i][j];
                  }
              }
              // console.log(headings);
              // console.log(totals);
              let goodFacts = new Array(colCount).fill(0);
              for (let j =0; j < colCount; j++) {
                let heading = headings[j].toLowerCase();
                if (colData.number.cols[j] < MIN_NUM_COUNT) goodFacts[j] = -10;
                if (heading.match(/options|rsu|sar|warrant/)) goodFacts[j]++;
                if (heading.match(/common|preferred|series/)) goodFacts[j]++;
                if (heading.match(/fully diluted|outstanding|ownership|\/sh|per/)) goodFacts[j]--;
                if (heading.match(/total|price/)) goodFacts[j]--;
                if (totals[j] > Math.max(...totals)) goodFacts[j]--;  // <-- This is delicate
                if (totals[j] <= Math.min(...totals)) goodFacts[j]++;
                if (totals[j] < 10) goodFacts[j]--;
              }


              // deal with no emails, add dummy column
                if (nameCol == emailCol || emailCount < nameCount / 10) {
                    headings[colCount] = "DummyEmail";
                    emailCol = colCount;
                    colCount++;
                }

              //Create final array
              array.length = finishRow + 1;
              for (let i = 0; i < startRow; i++)
                array.shift();
              //console.log(array);
              let sortOrder = new Array(colCount).fill(999);
              sortOrder[0] = nameCol;
              sortOrder[1] = emailCol;
              let nextCol = 2;
              // Very delicate for keeping Class columns and ignoring Total columns (VET vs Dropps vs Greenfields)
              let okayFacts = Math.max(...goodFacts) - 1; // <-- This is delicate
              for (let j = 0; j < colCount; j++) {
                if (colData.number.cols[j] > MIN_NUM_COUNT && (goodFacts[j] >= 0 || goodFacts[j] >= okayFacts)) {
                  sortOrder[nextCol] = j;
                  nextCol++;
                }
              }
              //Rearrange the columns and create headings
              let sortedHeadings = new Array(nextCol).fill("");
              let headingRegex = /options?|rsu|sar|warrants?|common|preferred|series|\d+/
              let sortedArray = Array(array.length).fill(null).map(() => Array(nextCol));
              let badEmailArray = [];
              for(let j = 0; j < colCount; j++) {
                if (sortOrder[j] == 999) continue;
                for (let i = 0; i < array.length; i++) {
                  sortedArray[i][j] = array[i][sortOrder[j]];
                }       
                let heading = headings[sortOrder[j]];
                //console.log(heading);
                let words = heading.split(' ');
                heading = "";
                for (let k = 0; k < words.length; k++) {
                  if (words[k].toLowerCase().match(headingRegex) || words[k].length == 1)
                    heading += words[k] + " ";
                }
                if (heading.trim().length == 0) heading = headings[sortOrder[j]];
                if (heading.trim().length == 0) heading = "Unknown";
                sortedHeadings[j] = heading.trim();
              }

              // Insert new code
              // if (emailCol == 0) {
              //   for (let i = 0; i < sortedArray.length; i++) {
              //     if (sortedArray[i][1] == "") {
              //       sortedArray[i][1] = "no email provided";
              //       this.uploadError = true;
              //     }
              //   }
              // }

              // sortedHeadings[0] = "Name";
              // sortedHeadings[1] = "Email";

                sortedHeadings[0] = "Name";
                if (sortedHeadings[1] != "DummyEmail") {
                    sortedHeadings[1] = "Email";
                }
                sortedArray.unshift(sortedHeadings);
                colCount = sortedHeadings.length;
                // console.log(sortedArray);

              // sortedArray.unshift(sortedHeadings);
              // console.log(sortedArray);
              sortedArray = this.cleanPrefixes(sortedArray);
              //To Do: Clean up the Names column so that entries best conform to Firstname Lastname
              let nameColumn = sortedArray.map(j => j[0]);
              let commaCount = nameColumn.filter(text => text.includes(",")).length;
              //console.log(nameColumn);
              //console.log(commaCount);
              let suffixRegex = /^(L\.?P\.?|L\.?L\.?C\.?|P\.?C\.?|J\.?T\.?|INC\.?|CORP\.?|JTROS\.?|JR\.?|SR\.?|II|III|IV|V|VI)$/;
              if (commaCount / nameColumn.length > 0.5) {
                    for (let i = 1; i < nameColumn.length; i++) {
                      if (nameColumn[i].length > 40) continue;
                      let names = nameColumn[i].split(',');
                      // if (i == 84)
                      //  console.log(names);
                      if (names.length == 1) continue;
                      let lastName = names[0].trim();
                      names.shift();
                      //handle interim suffix
                      if (names.length > 1 && names[0].trim().toUpperCase().match(suffixRegex)) {
                        let suffix = names[0].trim();
                        names.shift();
                        sortedArray[i][0] = (names.join(' ').trim() + " " + lastName + ", " + suffix).replace('  ',' ').trim();
                        continue;
                      }
                      //handle ending suffix
                      let firstNames = names.join().trim().split(' ');
                      if (firstNames.length > 0) {
                        if (firstNames[firstNames.length-1].toUpperCase().match(suffixRegex)) {
                          let suffix = firstNames[firstNames.length-1].trim();
                          firstNames.length = firstNames.length-1;
                          sortedArray[i][0] = (firstNames.join(' ').trim() + " " + lastName + ", " + suffix).replace('  ',' ').trim();
                          continue;
                        }
                      } else if (names[0].toUpperCase().match(suffixRegex)) continue;
                      sortedArray[i][0] = (names.join().trim() + " " + lastName).replace('  ',' ').trim();
                    }
                    // console.log(sortedArray);
                    sortedArray = this.cleanPrefixes(sortedArray);
                }
                // identify missing emails and handle duplicate emails
                badEmailArray = sortedArray.slice(0, 1);
                for (let i = 1; i < sortedArray.length; i++) {
                    if (sortedArray[i][1] == undefined || sortedArray[i][1].length < 6) {
                        sortedArray[i][1] = "no email provided";
                        badEmailArray.push(sortedArray[i]);
                        sortedArray.splice(i, 1);
                        //console.log(badEmailArray[badEmailArray.length-1]);
                        i--;                    
                    } else {
                        for (let j = 0; j < i; j++) {
                            if (sortedArray[i][1] == sortedArray[j][1]) {
                                if (sortedArray[j][0].trim() != sortedArray[i][0].trim())
                                    sortedArray[j][0] = sortedArray[j][0] + " + " + sortedArray[i][0];
                                for (let k = 2; k < colCount; k++) {
                                    let valueI = sortedArray[i][k];
                                    let valueJ = sortedArray[j][k];
                                    if (isNaN(valueI) || isNaN(valueJ)) {
                                        if (!isNaN(valueI)) sortedArray[j][k] = valueI;
                                    } else {
                                        valueJ += valueI;
                                        sortedArray[j][k] = valueJ;
                                    }
                                }
                                sortedArray.splice(i, 1);
                                //console.log(sortedArray[j]);
                                i--;
                                break;
                            }
                        }
                    }
                }

                // Get all numbers back to strings
                for (let j = 1; j < sortedArray.length; j++) {
                    for (let k = 2; k < colCount; k++) {
                        sortedArray[j][k] = sortedArray[j][k].toString();
                    }
                }
                for (let j = 1; j < badEmailArray.length; j++) {
                    for (let k = 2; k < colCount; k++) {
                        badEmailArray[j][k] = badEmailArray[j][k].toString();
                    }
                }

                // Flatten and trim email addresses         
                for (let j = 1; j < sortedArray.length; j++) {
                    sortedArray[j][1] = sortedArray[j][1].trim().toLowerCase();
                }

              console.log(sortedArray);
              if (sortedArray.length > 1) {
                console.log('Good', sortedArray);
                this.shareholders = sortedArray;
              } else {
                console.log('Only headings:', sortedArray);
                this.uploadError = true;
              }
              // console.log(badEmailArray)
              if (badEmailArray.length > 1) {
                this.badData = badEmailArray;
                console.log('Bad', badEmailArray);
                // this.uploadError = true
              }
              // sortedArray.unshift(sortedHeadings);
              // console.log(sortedArray);
              // console.log(sortedHeadings);
              
              //To Do: Clean up the Names column so that entries best conform to Firstname Lastname
            },
            loadFromFile (file) {
              // const file = ev.target.files[0];
              console.log(file);
              this.currentFile = file;
              let  rABS = typeof FileReader !== 'undefined' && FileReader.prototype && FileReader.prototype.readAsBinaryString;
              const reader = new FileReader();
              // reader.readAsText(file);
              if (rABS) {
                reader.readAsBinaryString(file);
              } else {
                reader.readAsArrayBuffer(file);
              }

              let that = this;
              // let csv = '';
              reader.onload = e => {
                 // csv = e.target.result;
                 // this.transformData(csv);
                 if (e.target.result.length > 1e8) {
                    // alert("File too large for timely process.");
                    // return;
                    this.uploadError = true;
                }

                let results = e.target.result;
                var wb, arr;
                var readtype = {type: rABS ? 'binary' : 'base64' };

                if(!rABS) {
                    // arr = fixdata(results);
                    results = btoa(arr);
                }

                function doit() {
                    try {
                        //sif(useworker) { sheetjsw(results, process_wb, readtype); return; }
                        wb = XLSX.read(results, readtype);
                        //process_wb(wb);
                        console.log("SLSX version = " + XLSX.version)
                        results = XLSX.utils.make_csv(wb.Sheets[wb.SheetNames[0]]);
                        console.log(results);
                        that.transformData(results);
                    } catch(err) { console.log(err); }  //opts.errors.failed(err); }
                }
                let opts = {};
                if (e.target.result.length > 1e8) {
                    opts.errors.large(e.target.result.length, function(e) { 
                        if (e) {
                            doit();
                        } 
                    });
                } else { 
                    doit(); 
                }
              }
            },
             deleteRecord () {
              //removes record from AWS
            },
        }
    }
</script>