<template>
    <div id="login" class="max-w-sm mx-auto">
        <transition name="fade">
            <div v-if="performingRequest" class="loading">
                <p>Loading...</p>
            </div>
        </transition>
        <section class="px-6 py-4 mt-12">
            <div class="col1">
                <h1 class="mb-4 mt-12 text-2xl leading-tight">Welcome back, you can log in securely below.</h1>
            </div>
            <div class="col2">
                <form @submit.prevent>

                    <label for="email">Email</label>
                    <input v-model.trim="loginForm.email" type="text" placeholder="you@email.com" id="email" class="appearance-none border rounded w-full py-4 px-3 text-grey-darker mb-4" />

                    <label for="password">Password</label>
                    <input v-model.trim="loginForm.password" type="password" placeholder="******" id="password" class="appearance-none border rounded w-full py-4 px-3 text-grey-darker mb-4" />

                    <button @click="login" class="bg-gray-800 hover:bg-gray-900 text-white font-bold py-4 px-4 rounded w-full mb-2">Log In</button>

                    <div class="flex justify-between">
                        <router-link :to="{ name: 'Create' }">Create an Account</router-link>
                    </div>
                </form>
                <transition name="fade">
                    <div v-if="errorMsg !== ''" class="error-msg">
                        <p>{{ errorMsg }}</p>
                    </div>
                </transition>
            </div>
        </section>
    </div>
</template>

<script>
    const fb = require('../firebaseConfig.js')
    export default {
        data() {
            return {
                loginForm: {
                    email: '',
                    password: ''
                },
                performingRequest: false,
                errorMsg: ''
            }
        },
        methods: {
            toggleForm() {
                this.errorMsg = ''
            },
            login() {
                this.performingRequest = true
                fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(user => {
                    this.$store.commit('setCurrentUser', user.user)
                    this.$store.dispatch('fetchUserProfile')
                    this.performingRequest = false
                    this.$router.push({name: 'Dashboard', params: user})
                }).catch(err => {
                    console.log(err)
                    this.performingRequest = false
                    this.errorMsg = err.message
                })
            }
        }
    }
</script>