<template>
    <div id="dashboard" class="px-4 md:px-20 bg-gray-50 min-h-screen">
        <div class="pb-8">
            <!-- <h1 class='text-4xl font-bold pt-12'>Hi {{ userProfile.name ? getFirstName(userProfile.name) : 'there' }},</h1> Commented out for now, issue with userProfile in store not being set for some users (potential firebase config needed)-->
            <h1 class='text-4xl font-bold pt-12'>Hi there,</h1>
            <p class="text-lg">What are you working on today?</p>
        </div>
        <div class='flex flex-wrap md:-mx-8'>
            <div v-if="showAddClient && showClientList || showFlagList" class="w-full md:w-1/3 md:px-8">
                <div class="shadow rounded p-4 mb-8 bg-white sticky" style="top: 2rem;">
                    <h3 class="mb-0 font-bold text-lg">Add a New Client</h3>
                    <p class="text-gray-600 mb-4">Generate a new signup link and bootstrap a new .io site to get them started. First, let's get some of their information.</p>
                    <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(createNewClient)" v-if="!currentlyImporting">

                        <validation-provider rules="required" v-slot="{ errors }" class="my-2 block">
                            <input v-model.trim="clientForm.company_name" placeholder="Company Name" type="text" id="title" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" />
                            <span class="text-red-500 text-xs mt-1">{{ errors[0] }}</span>
                        </validation-provider>

                        <validation-provider rules="required" v-slot="{ errors }" class="my-2 block">
                            <input v-model.trim="clientForm.workspace" placeholder="Workspace e.g., 'onward'" type="text" id="workspace" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" />
                            <span class="text-red-500 text-xs mt-1">{{ errors[0] }}</span>
                        </validation-provider>

                        <validation-provider rules="required" v-slot="{ errors }" class="my-2 block">
                            <input v-model.trim="clientForm.name" placeholder="Admin Name" type="text" id="name" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker"/>
                            <span class="text-red-500 text-xs mt-1">{{ errors[0] }}</span>
                        </validation-provider>

                        <validation-provider rules="required|email" v-slot="{ errors }" class="my-2 block">
                            <input v-model.trim="clientForm.email" placeholder="Admin Email" type="text" id="email" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker"/>
                            <span class="text-red-500 text-xs mt-1">{{ errors[0] }}</span>
                        </validation-provider>
                        <vue-dropzone ref="myVueDropzone" id="dropzone" v-on:vdropzone-file-added="saveFile" :options="dropzoneOptions" class="mb-4"></vue-dropzone>

                        <div class="w-full mb-4">

                        <validation-provider rules="required" v-slot="{ errors }" class="my-2 block">
                        <div class="relative">
                            <select v-model="clientForm.type" class="block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                              <option disabled value="">Please select one</option>
                                <option value="sampleData">Create with sample data</option>
                                <option value="adminImport">Nth Round to add data</option>
                                <option value="selfImport">Client to self onboard</option>
                            </select>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                          </div>
                          <span class="text-red-500 text-xs mt-1">{{ errors[0] }}</span>
                        </validation-provider>
                        </div>
                        <button :disabled='disableSaving' v-bind:class="{ 'opacity-50': disableSaving }" class="w-full bg-gray-800 hover:bg-gray-900 text-white py-2 px-3 rounded mb-2">Add Client</button>
                    </form>
                    </ValidationObserver>
                    <div v-if="currentlyImporting" class="h-48 flex items-center justify-center">
                        <div class="">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="mx-auto">
                                <rect x="0" y="13" width="4" height="5" fill="#333">
                                  <animate attributeName="height" attributeType="XML"
                                    values="5;21;5"
                                    begin="0s" dur="0.6s" repeatCount="indefinite" />
                                  <animate attributeName="y" attributeType="XML"
                                    values="13; 5; 13"
                                    begin="0s" dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="10" y="13" width="4" height="5" fill="#333">
                                  <animate attributeName="height" attributeType="XML"
                                    values="5;21;5"
                                    begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                  <animate attributeName="y" attributeType="XML"
                                    values="13; 5; 13"
                                    begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                </rect>
                                <rect x="20" y="13" width="4" height="5" fill="#333">
                                  <animate attributeName="height" attributeType="XML"
                                    values="5;21;5"
                                    begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                  <animate attributeName="y" attributeType="XML"
                                    values="13; 5; 13"
                                    begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                </rect>
                            </svg>
                            <div v-if="currentlyCreating" class="text-gray-600 text-sm text-center mt-4">Creating client...</div>
                            <div v-if="currentlyImporting" class="text-gray-600 text-sm text-center mt-4">Adding sample data...</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="w-full md:w-1/3 md:px-8">
                <div class="shadow rounded p-4 mb-8 bg-white sticky" style="top: 2rem;">
                    <h3 class="mb-4 font-bold text-lg">Add a New Code</h3>
<!--                     <p class="text-gray-600 mb-4">Generate a new signup link and bootstrap a new .io site to get them started. First, let's get some of their information.</p> -->
                    <form @submit.prevent>
                        <input v-model.trim="codeForm.code" placeholder="Code" type="text" id="code" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-4" />

                        <button @click="createNewCode" :disabled='disableSaving' v-bind:class="{ 'opacity-50': disableSaving }" class="w-full bg-gray-800 hover:bg-gray-900 text-white py-2 px-3 rounded mb-2">Add Code</button>
                    </form>
                </div>
            </div>

            <div class="w-full md:w-2/3 md:px-8 pb-12">
                <div class="flex justify-between mb-4 items-center">
                    <div class="flex cursor-pointer">
                        <div @click="toggle('showClientList')" class="mr-4 text-gray-600 text-lg" :class="{ 'text-gray-900 font-bold': showClientList }">Clients ( {{clients.length}} )</div>
                        <div hidden @click="toggle('showCodeList')" class="mr-4 text-lg text-gray-600" :class="{ 'text-gray-900 font-bold': showCodeList }">Codes ( {{codes.length}} )</div>
                        <div @click="toggle('showFlagList')" class="mr-4 text-lg text-gray-600" :class="{ 'text-gray-900 font-bold': showFlagList }">Flags &amp; Settings</div>
                    </div>
                    <label for="search" class="sr-only">Search projects</label>
                    <div class="relative text-indigo-200 focus-within:text-gray-400">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <!-- Heroicon name: solid/search -->
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div v-if="showClientList" >
                        <input v-model="search" placeholder="Search clients.." class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-0 placeholder-gray-400 text-gray-900 sm:text-sm" type="search" />
                      </div>
                      <div v-else-if="showFlagList" >
                        <input v-model="flagFilter" placeholder="Search flags and settings.." class="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 focus:outline-none focus:bg-white focus:ring-0 placeholder-gray-400 text-gray-900 sm:text-sm" type="search" />
                      </div>
                    </div>
                </div>

                <!-- client search results -->
                <div v-if="showClientList">
                    <div v-if="clients.length" class="">
                        <div v-bind:key="index" v-for="(client, index) in filteredList">
                            <div class="flex p-4 items-center shadow rounded mb-2 hover:shadow-md bg-white">
                                <router-link :to="{ name: 'Client', params: { id: client.id }}" class="w-1/2">
                                    <div>{{ client.company_name }} <span v-if="client.code_used"><span class="text-gray-600">Used Code:</span> <span class="text-orange-600">{{ client.code_used }}</span></span></div>
                                    <div class="text-gray-600 text-sm">{{ client.name }} ({{ client.email }})</div>
                                </router-link>
                                <div class="w-1/2">
                                    <div class="flex justify-end mb-2">
                                        <!-- <div>
                                            <a :href="'https://' + client.workspace + '.nthround.io'" target="_blank" class="bg-gray-100 py-1 px-2 text-gray-900 text-sm rounded">View Site</a> -->
                                        <!-- <button class="bg-gray-900 py-1 px-2 text-white text-sm rounded" @click="copyLink(client)">Copy Link</button> -->
                                        <!-- </div> -->
                                    </div>
                                    
                                    <p class="text-xs md:text-sm float-right text-teal-600">start.nthround.com/id/{{ client.id }}</p>
                                    <p class="text-xs md:text-sm float-right">{{client.workspace}}.nthround.io</p>
                                    <!-- <input type="text" class="float-right" :id="client.id" :value="client.id" /> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- code results -->
                <div v-if="showCodeList">
                    <div v-if="codes.length" class="">
                        <div v-bind:key="index" v-for="(code, index) in codes">
                            <div class="flex p-4 items-center shadow rounded mb-2 bg-white">{{ code.access_code }}</div>
                        </div>
                    </div>
                </div>

                <!-- flags search results -->
                <div v-if="showFlagList">
                    <div v-if="flagFilteredClients.length" class="">
                          <div v-for="rec in flagFilteredClients" :key="rec">
                            <div class="flex p-4 items-center shadow rounded mb-2 bg-white">{{ rec }}</div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const fb = require('../firebaseConfig.js');
    import { mapState } from 'vuex'
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import axios from 'axios'
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
    import { required, email } from 'vee-validate/dist/rules'

    extend('required', {
      ...required,
      message: 'This field is required'
    })
    extend('email', email);

    export default {
        data() {
            return {
                search: '',
                flagFilter: '',
                flagFilteredClients: [],
                showStatus: 'active',
                showClientList: true,
                showCodeList: false,
                showFlagList: false,
                showFlagClientsList: false,
                currentlyImporting: false,
                currentlyCreating: false,
                codeForm: {
                   code: '',
                },
                clientForm: {
                   name: '',
                   email: '',
                   company_name: '', 
                   logo: '',
                   workspace: '',
                   type: ''
                },
                disableSaving: false,
                uploadError: false,
                showAddClient: true,
                dropzoneOptions: {
                    dictDefaultMessage: 'Upload a logo',
                    url: 'https://httpbin.org/post',
                    thumbnailWidth: 150,
                    maxFilesize: 0.5,
                    maxFiles: 1,
                    addRemoveLinks: true,
                    headers: { "My-Awesome-Header": "header value" }
                }
            }
        },
        created() {
            const fetchData = async () => {
            const { data: flagFilteredClients } = await axios.get(
                'https://api.nthround.io/portal/auth/search-clients/'
            );

            this.flagFilteredClients = flagFilteredClients;
            console.log(this.flagFilteredClients)
            };

            fetchData();
        },
        components: { 
            vueDropzone: vue2Dropzone,
            ValidationProvider,
            ValidationObserver
        },
        mounted:{

        },
        computed: {
            ...mapState(['userProfile', 'clients', 'currentUser', 'codes']),
            isDisabled: function(){
                return !this.disableSaving;
            },
            activeClients () {
                return this.clients.filter(client => {
                    return client.status && client.status !== 'archived';
                  })
            },
            filteredList() {
                /** returns clients by searched name or company name from client obj in store*/
                return this.clients.filter(client => {
                        return client.company_name.toLowerCase().includes(this.search.toLowerCase()) || client.name.toLowerCase().includes(this.search.toLowerCase());
                })
            },
            filteredListTwo() {
                /** returns clients by searched name or company name */                
                return this.searchFlags(this.flagFilter.toLowerCase());
            }
        },
        methods: {
            // copyLink (client) {
            //     let elem = document.getElementById(client.id);
                
            //     elem.select()
            //     console.log(window.getSelection());
            //     try {
            //         var successful = document.execCommand('copy');
            //         var msg = successful ? 'successful' : 'unsuccessful';
            //         alert('Testing code was copied ' + msg);
            //       } catch (err) {
            //         alert('Oops, unable to copy');
            //       }

            //      window.getSelection().removeAllRanges()
            // },
            saveFile (file) {
                this.disableSaving = true;
                let _this = this;
                let url = 'https://api.cloudinary.com/v1_1/nthround/image/upload';
                  let uploadPreset = 'jv8dz1vj';
                  let fd = new FormData();
                  fd.append("upload_preset", uploadPreset);
                  fd.append("file", file);
                  let axconf = {headers: { "X-Requested-With": "XMLHttpRequest" }};
                  let vm = this;
                  axios.post(url, fd, axconf)
                   .then(function(res){
                     vm.clientForm.logo = res.data.secure_url;
                     console.log(res.data.secure_url);
                     _this.disableSaving = false;
                   })
                   .catch(function(err){
                     console.error(err);
                   });
            },
            getFirstName (fullname) {
                return fullname.split(' ').slice(0, -1).join(' ');
            },
            importShareholders (workspace) {
              let shareholders = [
                { 
                    user_name:"Morry Reddish1",
                    email:"mreddish0@ycombinator.com", 
                    shares: {
                        common: {
                            owned: "7163", 
                            approved: "7163", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Ddene Aymer",
                    email:"daymer1@hugedomains.com",
                    shares: {
                        common:{
                            owned: "7028", 
                            approved: "7028", 
                            committed:"0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Jana Schellig",
                    email:"jschellig2@yandex.ru",
                    shares: {
                        common: {
                            owned: "7826", 
                            approved: "7826", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Tawnya Plaxton",
                    email:"tplaxton3@meetup.com",
                    shares: {
                        common: {
                            owned: "5400", 
                            approved: "5400", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Renie Odom",
                    email:"rodom4@rediff.com",
                    shares: {
                        common: {
                            owned: "8645", 
                            approved: "8645", 
                            committed: "0", 
                            permitPurchase: true
                        }, 
                        preferred: {
                            owned: "3500", 
                            approved: "3500", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Arielle Podbury",
                    email:"apodbury5@wired.com",
                    shares: {
                        common: {
                            owned: "7543", 
                            approved: "7543", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Irvine Alker",
                    email:"ialker6@spotify.com",
                    shares: { 
                        common: {
                            owned: "4913",
                            approved: "4913", 
                            committed: "0", 
                            permitPurchase: true
                        },
                        preferred: {
                            owned: "1533", 
                            approved: "1533", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Herrick Dibb",
                    email:"hdibb7@reddit.com", 
                    shares: {
                        common: {
                            owned: "1308", 
                            approved: "1308", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Goldie Snoddy",
                    email:"gsnoddy8@cbc.ca",
                    shares: {
                        common: {
                            owned: "7331", 
                            approved: "7331", 
                            committed: "0", 
                            permitPurchase: true
                        },
                        preferred: {
                            owned: "369", 
                            approved: "369", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Carol-jean Krienke",
                    email:"ckrienke9@nasa.gov", 
                    shares: {
                        common: {
                            owned: "7994", 
                            approved: "7994", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Adina Berns",
                    email:"abernsa@hud.gov", 
                    shares: {
                        common: {
                            owned: "1154",
                            approved: "1154", 
                            committed: "0", 
                            permitPurchase: true
                        },
                        preferred: {
                            owned: "2324", 
                            approved: "2324", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Wallace Celes",
                    email:"wcelesb@wisc.edu", 
                    shares: {
                        common: {
                            owned: "5912", 
                            approved: "5912", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Carri Todhunter",
                    email:"ctodhunterc@forbes.com", 
                    shares: {
                        common: {
                            owned: "3126", 
                            approved: "3126", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Selina Swainson",
                    email:"sswainsond@joomla.org", 
                    shares: {
                        common: {
                            owned: "8095", 
                            approved: "8095", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Margalo Garton",
                    email:"mgartone@chronoengine.com", 
                    shares: {}, 
                    tags: ["Sample Data"]
                }
              ];

              let _this = this;
              this.currentlyImporting = true;
              
              let promises = [];

              // function createShareholder (shareholder) {

              // }

              shareholders.forEach(function (shareholder) {

                    // let shareholderObj = ;

                    // console.log(shareholderObj);

                    let postURL = 'https://oxx4gukklc.execute-api.us-east-1.amazonaws.com/presigned-exec/AQICAHjyZmzK8d2PGyyv1Zve93nUW1AyRl9VZtdIMbp8zlT_-QHIC9v54ruZY8YouriDkWEiAAAA2TCB1gYJKoZIhvcNAQcGoIHIMIHFAgEAMIG_BgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDF5_0NPpGSrm-ugVYgIBEICBkVsofShMXmejxucWphcWsu4Br3c8fnkU6-qDdtamrkx778j2U02mpH2uzEFMo84Rai1xaFU2Wko00DwmOk_5IdVy6JMCoZfKfT3_QyZ1tAjVDf6y_kc-MTjT6q4uGlUYmFCTdivz34RoJ_gFsVpLtFZ0d0dLzT4OiqnJpxf1u0kbn6pTqi7pLnBaoh4CBLNPf5A=?io.nthround.env=master'
                    
                    promises.push(
                    axios.post(postURL, {
                        user_name: shareholder.user_name,
                        user_img: 'https://www.gravatar.com/avatar/952b80295294f41ee6612f062c878ab3?s=80&d=retro',
                        userid: shareholder.email.toLowerCase(),
                        'io.nthround.env': 'master',
                        ethereum_address: null,
                        clients: {
                            [workspace]: {
                                investor: {
                                    complete: false,
                                    entity: false,
                                    income: false,
                                    netWorth: false,
                                    sophisticated: false
                                },
                                joinder: {
                                    complete: false
                                },
                                shares: shareholder.shares,
                                documents: [],
                                tags: shareholder.tags
                            }
                        }
                    })
                        .then(function(res) {
                            console.log(res)
                            let userlistURL = 'https://boz71b8oe5.execute-api.us-east-1.amazonaws.com/Stage/client/addmin?client=' + workspace + '&userid=' + _this.currentUser.email + '&userlist=' + shareholder.email;
                    axios.post(userlistURL)
                        .then(function(res){
                            console.log(res, 'Added: ' + shareholder.email);
                        })
                        .catch(function(err){
                            console.error(err);
                            _this.uploadError = true;
                        })  
                            
                        })
                        .catch(function(err){
                            console.error(err);
                            _this.uploadError = true;
                        })
                    )
                });

                axios.all(promises)
                    .then(function(values) {
                        console.log(values);
                        _this.currentlyImporting = false;
                        _this.clientForm.workspace = '';
                    })
                    .catch(function(err) {
                        console.error(err);
                    });

               
            },
            createPlatformClient (workspace, username, email, company, logo, type) {
                email = encodeURIComponent(email.toLowerCase());
                let url = 'https://boz71b8oe5.execute-api.us-east-1.amazonaws.com/Prod/client/newClientConfig?client=' + workspace + '&userid=' + email + '&user_name=' + username + '&company_name=' + company + '&plan=pro' + '&company_logo=' + logo;
                console.log(workspace, username, email, company);
                
                // Reset form values
                this.clientForm.company_name = '';
                this.clientForm.name = '';
                this.clientForm.email = '';
                this.$refs.myVueDropzone.removeAllFiles();
                let _this = this;

                axios.post(url)
                    .then(res => {
                        console.log(res);
                        this.currentlyCreating = false;
                        if (type == 'sampleData') {
                            this.importShareholders(workspace);
                        } else {
                            _this.clientForm.workspace = '';
                            _this.clientForm.type = '';
                        }

                        // ADD NEW CLIENT AND ADMIN TO COGNITO
                        axios.get('https://api.nthround.io/portal/auth/create-client',{
                            params: {
                                client: workspace,
                                userid: email,
                                user_name: username,
                                company: company,
                                plan: 'pro'
                            },
                            //withCredentials: true
                        })
                        .then(function (response) {
                            console.log(response);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                        console.log(res);
                        
                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            createNewClient () {
                this.currentlyCreating = true;
                let workspace = this.clientForm.workspace.trim().toLowerCase().replace(/\s/g, '')
                let logo = this.clientForm.logo ? this.clientForm.logo : 'https://res.cloudinary.com/nthround/image/upload//h_48/v1576255169/nth-logo.svg';
                fb.clientsCollection.add({
                    name: this.clientForm.name,
                    email: this.clientForm.email.toLowerCase(),
                    company_name: this.clientForm.company_name,
                    logo: logo,
                    user: this.currentUser.uid,
                    workspace: workspace,
                    type: this.clientForm.type,

                }).then(ref => {
                    console.log(ref.id);
                    this.createPlatformClient(workspace, this.clientForm.name, this.clientForm.email.toLowerCase(), this.clientForm.company_name, logo, this.clientForm.type);
                }).catch(err => {
                    console.log(err);
                })
            },
            createNewCode () {
                fb.codesCollection.add({
                    access_code: this.codeForm.code.toUpperCase(),
                    created: Math.round((new Date()).getTime() / 1000),
                    user: this.currentUser.uid
                }).then(ref => {
                    this.codeForm.code = '';
                    console.log(ref.id);
                }).catch(err => {
                    console.log(err);
                })
            },
            toggle (list) {
                /** handles toggling for tabs navigation */
                switch(list) {
                    case 'showClientList':
                        this.showClientList = true
                        this.showCodeList = false
                        this.showFlagList = false
                        break;
                    case 'showCodeList':
                        this.showClientList = false
                        this.showCodeList = true
                        this.showFlagList = false
                        break;
                    case 'showFlagList':
                        this.showClientList = false
                        this.showCodeList = false
                        this.showFlagList = true
                        break;
                    default:
                        
                }
            },
            async updateFilteredClients(flag) {
                const flagFilteredClients = await this.searchFlags(flag);
                this.flagFilteredClients = flagFilteredClients;
            },
            async searchFlags(flag) {
                const flagFilterEndpoint = 'https://api.nthround.io/portal/auth/search-clients/';
                try {
                const response = await axios.get(flagFilterEndpoint, {
                    params: {
                    string: flag
                    }
                });
                console.log("search results: " + response.data);
                return response.data;
                } catch (error) {
                console.error(error);
                return [];
                }
            },
            // getClientName(rec) {
            //     const client = this.flagFilteredClients.find(c => c.company_name === rec);
            //     return client ? rec : '';
            // }
            // getClientId(rec) {
            //     console.log(this.clients);
            //     const matchingClient = this.clients.find(client => client.company_name === rec);
            //     console.log(matchingClient);
            //     return matchingClient ? matchingClient.id : null;
            // }
            },
            watch: {
            flagFilter(newFlag) {
                console.log("flagFilter change to: " + newFlag);
                this.updateFilteredClients(newFlag.toLowerCase());
                
            }
        }
    }
</script>