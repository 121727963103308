import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// firebase init goes here
const config = {
	apiKey: "AIzaSyDV-Sep8QBn7tFs0q2FOn-4H9e4i3McyvY",
    authDomain: "nth-onboarding.firebaseapp.com",
    databaseURL: "https://nth-onboarding.firebaseio.com",
    projectId: "nth-onboarding",
    storageBucket: "nth-onboarding.appspot.com",
    messagingSenderId: "283862237867",
    appId: "1:283862237867:web:45640a6fff46da350b2dee",
    measurementId: "G-P99WFHRFGF"
}
firebase.initializeApp(config)
// firebase.analytics();

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser

// firebase collections
const usersCollection = db.collection('users')
const clientsCollection = db.collection('clients')
const codesCollection = db.collection('codes')

export {
    db,
    auth,
    currentUser,
    usersCollection,
    clientsCollection,
    codesCollection
}