<template>
    <div id="start" class="flex h-screen md:flex-row-reverse flex-wrap">
        
        <div ref="mainPanel" v-if="currentTask !== 4" class="w-full md:w-2/3 md:h-screen md:overflow-y-auto">

            <div class="pt-10 pb-20 px-4 md:px-32" v-if="currentTask === 0">
                <!-- <img src="https://res.cloudinary.com/nthround/image/upload//h_48/v1576255169/nth-logo.svg" alt="Nth Round Logo" class="w-48 mb-6" /> -->
                <img :src="currentClientProfile.logo ? currentClientProfile.logo : 'https://res.cloudinary.com/nthround/image/upload//h_48/v1576255169/nth-logo.svg'" class="w-48 mb-6">
                <h1 class="text-3xl mb-2">Welcome to Nth Round.</h1>
                <p class="text-gray-600 mb-4">From day one, we partner with you. Our goal is to provide as much value to you as possible. Our product is designed to save you time, delight your shareholders, and maintain compliance—but our real service is peace of mind. 

                <p class="text-gray-600 mb-4">We’re 100% committed to propelling you and your company forward. Whether fundraising, enabling employee ownership, or communicating with shareholders, we will be with you every step of the way.</p>

                <p class="text-gray-600 mb-2">– We look forward to working with you!</p>
                <img src="./../assets/gmc_signature.png" class="w-1/2 mb-8">
                <button v-on:click="incrementTask" class="bg-gray-900 hover:bg-gray-700 text-white font-bold py-3 px-4 rounded">
                    Next Step: Our Commitments
                </button>
            </div>

            <div v-if="currentTask === 1">
                <div class="pt-10 pb-20 px-4 md:px-32">
                    <h1 class="text-3xl mb-2">Let's talk about our commitment</h1>
                    <p class="text-gray-600 mb-8">We are available 24/7 whenever you need anything. Your dedicated Customer Success Manager, will be at your service anytime, day or night. That said, should you desire domain-specific expertise, every member of our team is eager to help.</p>

                    <h3 class="mb-4 text-xl">Your Nth Round Equity Platform includes:</h3>
                    <div class="flex mb-8 flex-wrap -mx-2 border rounded">
                        <div class="w-full md:w-1/2 px-2 mb-4">
                            <div class="p-4 h-full">
                                <h5 class="font-bold mb-2 uppercase">Shareholder Engagement</h5>
                                <p class="text-gray-600 mb-2 text-sm">Create a compelling shareholder experience with equity valuation, graphics, personalized communications, reports, and stored documents.</p>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 px-2 mb-4">
                            <div class="p-4 h-full">
                                <h5 class="font-bold mb-2 uppercase">Regulatory Compliance</h5>
                                <p class="text-gray-600 text-sm">Navigate challenging regulations with seamless processes and resources designed to provide peace of mind and reduce legal costs.</p>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 px-2 mb-4">
                            <div class="p-4 h-full">
                            <h5 class="font-bold mb-2 uppercase">Cap Table Management</h5>
                            <p class="text-gray-600 text-sm">See the full picture of your equity. Issue digital assets (shares/units), track ownership, and control access for shareholders and others.</p>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 px-2  mb-4">
                            <div class="p-4 h-full">
                            <h5 class="font-bold mb-2 uppercase">Liquidity (optional)</h5>
                            <p class="text-gray-600 mb-2 text-sm">When ready, provide a private trading environment available 24/7 to you, your shareholders, and any new investors that you'd like to invite.</p>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 px-2">
                            <div class="p-4 h-full">
                                <h5 class="font-bold mb-2 uppercase">Equity Incentives</h5>
                                <p class="text-gray-600 mb-2 text-sm">Energize your team by issuing Options, RSUs, SARs, whatever plan you prefer.  To maximize impact, convey share certificates, signature pages, and vesting schedules in private online vaults easily accessible by you and your employees.</p>
                            </div>
                        </div>
                        <div class="w-full md:w-1/2 px-2">
                            <div class="p-4 h-full">
                                <h5 class="font-bold mb-2 uppercase">Concierge Services</h5>
                                <p class="text-gray-600 mb-2 text-sm">Tap into your dedicated customer success representative, who will provide strategic, proactive support and serve as your day-to-day point of contact.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fixed bottom-0 p-2 bg-gray-100 w-full md:w-2/3 flex justify-between md:px-32">
                    <button v-on:click="decrementTask" class="border hover:bg-gray-200 text-gray-900 font-bold py-2 px-4 rounded mr-4">
                        Go Back
                    </button>
                    <button v-on:click="incrementTask" class="bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                        Next: Get Access
                    </button>
                </div>
            </div>

            <div v-if="currentTask === 2">
                <div class="pt-10 pb-20 px-4 md:px-32">
                    <h1 class="text-3xl mb-2">Let's make it official</h1>
                    <p class="text-gray-600 mb-8">We ask for your credit card to create your account, but <span class="font-medium">we won't bill you until you have completed your account setup and scheduled an onboarding call with your account manager.</span> We’re 100% committed to propelling you and your company forward.</p>
                    <div class="border rounded mb-8 flex items-center py-4 px-8">
                        <div class="w-1/3">
                            <div class="flex mb-2">
                               <div class="self-start">$</div>
                               <div class="self-center text-3xl font-bold leading-none">1000</div>
                               <div class="self-end">/m</div>
                            </div>
                            <div class="text-sm text-gray-600">Paid monthly, or <br>$11k paid annually</div>
                        </div>
                        <div class="w-2/3 text-sm">
                            <div class="flex items-center mb-1 text-gray-600">
                                <svg class="fill-current text-teal-400 inline-block h-3 w-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2 2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v18l-8-4-8 4V2zm2 0v15l6-3 6 3V2H4z"/></svg>
                                No transaction or hidden fees
                            </div>
                            <div class="flex items-center mb-1 text-gray-600">
                                <svg class="fill-current text-teal-400 inline-block h-3 w-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2 2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v18l-8-4-8 4V2zm2 0v15l6-3 6 3V2H4z"/></svg>
                                Service Level Agreement
                            </div>
                            <div class="flex items-center text-gray-600">
                                <svg class="fill-current text-teal-400 inline-block h-3 w-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2 2c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v18l-8-4-8 4V2zm2 0v15l6-3 6 3V2H4z"/></svg>
                                24/7 Phone and email support
                            </div>
                        </div>
                    </div>
                    <div>
                        <card class='border p-2 rounded'
                          :class='{ complete }'
                          stripe='pk_live_tInGQcTlscl7eD67nm6V0mW9'
                          :options='stripeOptions'
                          @change='complete = $event.complete'
                        />

                        <div v-if="showCardError && !complete" class="text-red-500 text-sm mt-1" role="alert">Your card number is incomplete.</div>
                        
                        <div class="my-2 text-center text-gray-600 text-sm">or</div>
                        <input v-model.trim="accessCode" @input='updateCode' placeholder="Enter an access code" type="text" id="name" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-4"/>
                        <div v-if="codeError" class="text-red-500 text-sm mt-1" role="alert">Code not found, please try again or enter payment details.</div>
                        <button @click='pay' :disabled='!complete' v-bind:class="{ 'opacity-50 cursor-not-allowed': !complete }" class='mt-4 pay-with-stripe bg-gray-900 hover:bg-gray-700 text-white w-full font-bold py-3 px-4 rounded'>Get Access Today</button>

                        <div class="mt-2 text-sm text-gray-400 flex items-center">
                            <svg class="fill-current text-gray-400 inline-block h-3 w-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"/></svg>

                            To protect your financial data, we use industry standard AES-256 and TLS encryption.</div>

                    </div>
                </div>
                <div class="fixed bottom-0 p-2 bg-gray-100 w-full md:w-2/3 flex justify-between md:px-32">
                    <button v-on:click="decrementTask" class="border hover:bg-gray-200 text-gray-900 font-bold py-2 px-4 rounded mr-4">
                        Go Back
                    </button>
                </div>
            </div>

            <div v-if="currentTask === 3">
                <div class="pt-12 pb-20 px-4 md:px-32 text-center">
                    <img src="https://res.cloudinary.com/nthround/image/upload//h_48/v1576255169/nth-logo.svg" class="w-48 mb-6 mx-auto">
                    <h1 class="text-3xl mb-4">Last step, import your cap table</h1>
                    <p class="text-gray-600 mb-8 font-medium">Have a cap table with <b>emails</b> handy?<br/>Import it, send it to us, or get started with sample data.</p>

                    <div v-if="!uploadToSend" class="mx-8">
                        <div class="shadow hover:shadow-lg cursor-pointer rounded mb-6 p-4" @click="incrementTask">
                            <h3 class="text-xl font-medium mb-2">I'll upload it myself</h3>
                            <p class="text-gray-600">This takes just a few seconds.</p>
                        </div>
                        <div class="shadow hover:shadow-lg cursor-pointer rounded mb-6 p-4" @click="showUploadToSend">
                            <h3 class="text-xl font-medium mb-2">Send to an onboarding specialist</h3>
                            <p class="text-gray-600">Great for complex spreadsheets.</p>
                        </div>
                        <div class="shadow hover:shadow-lg cursor-pointer rounded mb-6 p-4" @click="createSampleData">
                            <h3 class="text-xl font-medium mb-2">Start with sample data</h3>
                            <p class="text-gray-600">Perfect for testing. Add your own data at any time.</p>
                        </div>
                    </div>

                    <div v-if="uploadToSend && !uploadSent" @load="text = $event">
                        <div class="hidden md:block pt-10 pb-20"> 

                            <div class="mb-4">
                                <vue-dropzone ref="myVueDropzone" v-on:vdropzone-file-added="loadFromFile" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                            </div>
                            <button @click="requestSupport" v-bind:class="[currentFile == null ? disabled : '']" class='bg-gray-900 hover:bg-gray-700 text-white w-full font-bold py-3 px-4 rounded mb-6 mt-4'>Send to Us</button>
                        </div>
                    </div>
                    

                    <div v-if="uploadSent" class="p-8 bg-gray-100 rounded">
                        <p class="text-gray-600 mb-0 font-medium">We received your cap table upload and will email you shortly with details about how to access your new account.</p>
                    </div>
                </div>
                <div class="fixed bottom-0 p-2 bg-gray-100 w-full md:w-2/3 flex justify-between md:px-32">
                    <button @click="uploadToSend = false;" v-on:click="decrementTask" class="border hover:bg-gray-200 text-gray-900 font-bold py-2 px-4 rounded mr-4">
                        Go Back
                    </button>
                </div>
            </div>
            

            <div v-if="currentTask > 4">
                <div class="pt-12 pb-20 px-4 md:px-32 text-center">
                    <svg class="bg-teal-600 p-6 mb-8 rounded-full mx-auto text-center h-24 w-24 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                    <h1 class="text-3xl mb-4">Your Account is Ready!</h1>
                    <!-- <p class="text-gray-600 mb-8 font-medium">We've sent you a private authentication link<span v-if="currentClientProfile.email"> at <span class="font-bold text-gray-700">{{ currentClientProfile.email }}</span></span>, please check your inbox and click the link to get started.</p> -->
                    <p class="text-gray-600 mb-8 font-medium">You now have access to your secure shareholder portal, click the link below to get started.</p>
                    <a :href="authLink" v-if="authLink.length" class="border border-gray-900 bg-gray-900 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4">Go to Dashboard</a>
                    <img src="./../assets/celebration.jpg" class="w-2/3 mx-auto py-8" alt="Jumping in celebration" />
                    <p class="text-center text-gray-600">Didn't receive an email? <span class="text-teal-600 cursor-pointer" @click="sendAuthLink">Resend Authentication Link</span></p>
                </div>
            </div>
            
        </div>

        <div v-if="currentTask === 4">
                <div class="pt-12 pb-20 px-4 md:px-32 text-center">
                    <div class="md:px-32">
                        <img src="https://res.cloudinary.com/nthround/image/upload//h_48/v1576255169/nth-logo.svg" class="w-48 mb-6 mx-auto">
                        <h1 class="text-3xl mb-4">Import Shareholders</h1>
                        <p class="text-gray-600 mb-8 font-medium">Nth Round makes it easy to see the full picture of your equity.<br/>Issue digital assets, track ownership, understand shareholder engagement.</p>
                    </div>
                    
                    <div  @load="text = $event">
                        <div class="hidden md:block pt-10 pb-20 md:px-32"> 

                            <div v-if="!shareholders.length && !uploadError && !currentlyImporting" class="mb-4">
                                <vue-dropzone ref="myVueDropzone" v-on:vdropzone-file-added="loadFromFile" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                            </div>

                            <div v-if="uploadError" class="border border-red-600 rounded p-8 bg-gray-100">
                                <h3 class="font-bold text-xl mb-2">We were unable to import your cap table automatically</h3>
                                <p class="mb-4 text-gray-700">Don't worry, our team is standing by to help. Click below to send your cap table directly to your Nth Round account manager and we'll get everything taken care of and send you a notification when your account is ready. To make changes and upload your cap table again <span @click="uploadError = false, shareholders = [], badData = []" class="text-teal-600 cursor-pointer">click here</span>.</p> 

                                <button @click="requestSupport" class="border border-gray-900 hover:bg-gray-200 text-gray-900 font-bold py-2 px-4 rounded mt-4">Send to Support</button>
                            </div>



                            <table v-if="shareholders.length && !currentlyImporting && !uploadError" class="bg-white mt-8 w-full">

                              <tr v-bind:key="item[0]" v-for="item in shareholders" class='text-sm'>
                                <td class="border px-4 py-2">{{ item[0] }}</td>
                                <td class="border px-4 py-2">{{ item[1] }}</td>
                                <td class="border px-4 py-2">{{ item[2] ? item[2] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[3] ? item[3] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[4] ? item[4] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[5] ? item[5] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[6] ? item[6] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[7] ? item[7] : '' }}</td>
                              </tr>
                            </table>

                            <div v-if="currentlyImporting" class="h-48 flex items-center justify-center">
                                <div v-if="!capTableUploaded">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="mx-auto">
                                        <rect x="0" y="13" width="4" height="5" fill="#333">
                                          <animate attributeName="height" attributeType="XML"
                                            values="5;21;5"
                                            begin="0s" dur="0.6s" repeatCount="indefinite" />
                                          <animate attributeName="y" attributeType="XML"
                                            values="13; 5; 13"
                                            begin="0s" dur="0.6s" repeatCount="indefinite" />
                                        </rect>
                                        <rect x="10" y="13" width="4" height="5" fill="#333">
                                          <animate attributeName="height" attributeType="XML"
                                            values="5;21;5"
                                            begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                          <animate attributeName="y" attributeType="XML"
                                            values="13; 5; 13"
                                            begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                                        </rect>
                                        <rect x="20" y="13" width="4" height="5" fill="#333">
                                          <animate attributeName="height" attributeType="XML"
                                            values="5;21;5"
                                            begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                          <animate attributeName="y" attributeType="XML"
                                            values="13; 5; 13"
                                            begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                                        </rect>
                                    </svg>
                                    <div class="text-gray-600 text-sm text-center mt-4">Importing shareholders...</div>
                                </div>
                                <div v-if="capTableUploaded" class="h-48 flex items-center justify-center">
                                    <div class="pt-12 text-center">
                                        <svg class="bg-teal-600 p-6 mb-8 rounded-full mx-auto text-center h-24 w-24 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                                        <h1 class="text-3xl mb-4">Success!</h1>
                                    </div>
                                </div>
                            </div>

                            

                            <div v-if="shareholders.length > 5 && !uploadError && !currentlyImporting" class="text-center text-gray-500 mt-2">Showing <b>{{ shareholders.length - 1}}</b> records. Some right-hand columns may be intentionally blank.</div>

                            
                            <div class="fixed left-0 bottom-0 p-2 bg-gray-100 w-full flex justify-between md:px-64">
                                <button v-on:click="decrementTask" @click="uploadError = false, shareholders = [], badData = []" class="border hover:bg-gray-200 text-gray-900 font-bold py-2 px-4 rounded mr-4">
                                    Go Back
                                </button>
                                <button @click="importShareholders(shareholders)" v-bind:class="[shareholders.length ? disabled : '']" v-if="shareholders.length && !uploadError && !currentlyImporting" class='bg-gray-900 hover:bg-gray-700 text-white font-bold py-3 px-4 rounded'>Import to Nth Round</button>
                            </div>

                            <!-- Display the errors here -->
                            <div v-if="badData.length" class="p-2 text-gray-700 bg-yellow-500 flex justify-between mt-8">
                                <div>We found <span class="font-bold">{{ badData.length - 1 }}</span> rows with errors.</div>
                                <!-- <button @click="showAllErrors">Show all</button> -->
                            </div>
                            <table v-if="badData.length" class="bg-white w-full mb-4">

                              <tr v-bind:key="item[0]" v-for="item in badData" class='text-sm'>
                                <td class="border px-4 py-2">{{ item[0] }}</td>
                                <td class="border px-4 py-2">{{ item[1] }}</td>
                                <td class="border px-4 py-2">{{ item[2] ? item[2] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[3] ? item[3] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[4] ? item[4] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[5] ? item[5] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[6] ? item[6] : '' }}</td>
                                <td class="border px-4 py-2">{{ item[7] ? item[7] : '' }}</td>
                              </tr>
                            </table>


                            <p class="text-gray-600" v-if="shareholders.length && !uploadError && !currentlyImporting">
                                <svg class="fill-current text-orange-400 inline-block h-4 w-4 mb-1 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                                To make changes and upload your cap table again <span @click="uploadError = false, shareholders = [], badData = []" class="text-teal-600 cursor-pointer">click here</span>. If something doesn't look quite right in the data please <span class="text-teal-600 cursor-pointer" @click="requestSupport">click here</span> to send your cap table to your account manager who will be happy to help.
                            </p>
                        </div>

                        <div class="block md:hidden px-8 text-center bg-gray-200 h-screen">
                            <img src="https://res.cloudinary.com/nthround/image/upload//h_48/v1576255169/nth-logo.svg" alt="Nth Round Logo" class="mx-auto w-24 pt-4 mb-16">
                            <h3 class="text-3xl font-bold mb-4">To finish setting up your Nth Round account go to your computer.</h3>
                            <p class="">We just sent you an email with a link to your Nth Round account. See you soon.</p>
                        </div>
                        
                    </div>
                </div>
            </div>


        <div v-if="currentTask !== 4" class="w-full md:w-1/3 bg-gray-100 md:h-screen">
            <div v-if="currentTask === 0" class="flex h-screen">
                <div class="self-end">
                    <img src="./../assets/gmc_dec.png" class="px-12 md:px-0" />
                    <div class='bg-teal-400 p-4'>
                        <h5 class="text-gray-900"><span class="font-bold">Graham McConnell</span> - CEO</h5>
                    </div>
                </div>
            </div>
            <!-- <div v-if="currentTask === 1" class="flex min-h-screen">
                <div class="self-end">
                    <img src="./../assets/gmc_dec.png" class="">
                    <div class='bg-teal-400 p-4'>
                        <h5 class="text-gray-900"><span class="font-bold">Graham McConnell</span> - CEO</h5>
                    </div>
                </div>
            </div> -->
            <div v-if="currentTask === 1" class="hidden md:flex md:items-center md:h-screen bg-gray-300 md:p-8">
                <div class="bg-white rounded-lg shadow">
                    <img src="./../assets/draft_data.jpg">
                    <div class='p-4 bg-gray-400'>
                        <p class="text-gray-900 mb-2">"We’re able to unlock growth opportunities for our platform by incentivizing engagement with real ownership."</p>
                        <h5 class="text-gray-700"><span class="font-bold">Gui Costin</span></h5>
                        <p class="text-gray-700">CEO – Draft Data</p>
                    </div>
                </div>
            </div>
            <div v-if="currentTask === 2" class="flex items-center h-screen bg-gray-300 p-8">
<!--                 <div class="bg-white rounded-lg shadow">
                    <img src="./../assets/triple_bottom.jpg">
                    <div class='p-4 bg-gray-400'>
                        <p class="text-gray-900 mb-2">"We’re able to provide ownership to key employees without battling red tape or sacrificing any control."</p>
                        <h5 class="text-gray-700"><span class="font-bold">Tess Hart</span></h5>
                        <p class="text-gray-700">Founder – Triple Bottom Brewing</p>
                    </div>
                </div> -->
                <div class="bg-white rounded-lg shadow">
                    <img src="./../assets/dorazio.png">
                    <div class='p-4 bg-gray-400'>
                        <p class="text-gray-900 mb-2">"Nth Round's equity management platform allows us to easily issue, track, and distribute ownership information. Ease of setup, service, and follow-up by the Nth Round team has been fantastic."</p>
                        <h5 class="text-gray-700"><span class="font-bold">Duane D'Orazio</span></h5>
                        <p class="text-gray-700">Managing Partner and Chief Compliance Officer – Conestoga Capital</p>
                    </div>
                </div>
            </div>
            <div v-if="currentTask > 2" class="flex items-center h-screen bg-gray-300 p-8">
                <div class="bg-white rounded-lg shadow">
                    <img src="./../assets/gmc_dec.png">
                    <div class='p-4 bg-gray-400'>
                        <p class="text-gray-900 mb-2">"We're so excited to welcome you to Nth Round. Helping you to delight shareholders is our top priority. We're available anytime to ensure your success."</p>
                        <h5 class="text-gray-700"><span class="font-bold">Graham McConnell</span></h5>
                        <p class="text-gray-700">CEO – Nth Round</p>
                    </div>
                </div>
            </div>
            <!-- <div v-if="currentTask > 2" class="hidden md:flex h-screen">
                <div class="self-center bg-white p-1 shadow rounded ml-8">
                    <img src="./../assets/shareholders_screen.png" class="w-full" />
                </div>
            </div> -->
        </div>
        
    </div>
</template>

<script>
    import firebase from 'firebase/compat/app';
    import { mapState } from 'vuex'
    // import { stripeKey, stripeOptions } from './stripeConfig.js'
    import { Card, createToken } from 'vue-stripe-elements-plus'
    import Papa from 'papaparse'
    import XLSX from 'xlsx'
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import axios from 'axios'
    export default {
        created() {
            // If a client ID is specified in the url fetch the client profile from Firebase
            if (this.$route.params.id) {
                console.log(this.$route.params.id)
                this.$store.dispatch('fetchCurrentClientProfile', this.$route.params.id);
                
            } else {
                window.location = 'https://www.nthround.com';
            }
        },
        mounted () {
            window.analytics.page('Start')
            // console.log(this.$store.state["currentClientProfile"].name);
            window.analytics.identify(this.$route.params.id, {
              name: this.$store.state["currentClientProfile"].name,
              email: this.$store.state["currentClientProfile"].email
            });

            if (this.$store.state["currentClientProfile"].token) {
                this.currentTask = 3
            }
            
        },
        // beforeUpdate () {
        //     console.log(this.$store.state["currentClientProfile"].token)
        //     if (this.$store.state["currentClientProfile"].token) {
        //         this.currentTask = 4
        //     }
        // },
        data() {
            return {
                authLink: '',
                uploadToSend: false,
                uploadSent: false,
                currentTask: 0,
                performingRequest: false,
                showCardError: false,
                complete: false,
                disabled: true,
                shareholders: [],
                badData: [],
                uploadError: false,
                currentFile: null,
                currentlyImporting: false,
                capTableUploaded: false,
                accessCode: '',
                codeError: false,
                userApi: 'https://oxx4gukklc.execute-api.us-east-1.amazonaws.com/presigned-exec/AQICAHjyZmzK8d2PGyyv1Zve93nUW1AyRl9VZtdIMbp8zlT_-QGNIk_itJ0ParpLeJiClnuLAAAA6TCB5gYJKoZIhvcNAQcGoIHYMIHVAgEAMIHPBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDLJN4EKzBbSlwPHNLgIBEICBoZx9i6PosKU9wp1GUNWZtgPYj2t11uVfyRrZ1RClNa0EqWiVFK41WLKBsGCTVjsESkDBoL6jYUZELqDPLi8jQQjXKs5Ml1RheGEqnMq1UMXKpuHTCvbOKaVlNP2NDx7FvD9miEpw6iQMpfzOSkIJZpgVaLQ9sSXQ_d2nkP9pa8wZH1AraLU0zGveE2-sc_FiLKLKxMDS-tYKST-LWoTU2dvK',
                dropzoneOptions: {
                    dictDefaultMessage: 'Click this box to upload your cap table as a xlsx, xls, xml, csv, tsv, txt, or rtf file. <br/> <br/> Each row must include: Name, <b>Email</b>, and Quantities for each share class.<br/>Share class headings should be short <b>abbreviations or symbols</b>.',
                    url: 'https://httpbin.org/post',
                    thumbnailWidth: 150,
                    maxFilesize: 0.5,
                    maxFiles: 1,
                    addRemoveLinks: true,
                    headers: { "My-Awesome-Header": "header value" }
                },
                stripeOptions: {
                    // see https://stripe.com/docs/stripe.js#element-options for details
                }
            }
        },
        components: { 
            Card,
            vueDropzone: vue2Dropzone
        },
        computed: {
            ...mapState(['userProfile', 'currentClientProfile'])
        },
        methods: {
            showUploadToSend: function () {
                this.uploadToSend = true
            },
            incrementTask: function () {
                if (this.$refs.mainPanel) this.$refs.mainPanel.scrollTop = 0;
                this.currentTask += 1
            },
            decrementTask: function () {
                if (this.$refs.mainPanel) this.$refs.mainPanel.scrollTop = 0;
                this.currentTask -= 1
            },
            requestSupport: function () {
                let url = 'https://api.cloudinary.com/v1_1/nthround/auto/upload'
                let uploadPreset = 'ytkdysdh'
                let fd = new FormData()
                fd.append("upload_preset", uploadPreset)
                fd.append("file", this.currentFile)
                let axconf = {
                headers: { "X-Requested-With": "XMLHttpRequest" },
                resource_type: 'auto'
                };
                let _this = this
                axios.post(url, fd, axconf)
                .then(function(res){
                     console.log(res);
                     let fileToUpload = res.data.secure_url;
                     console.log(fileToUpload);
                     let url = 'https://hooks.zapier.com/hooks/catch/4626966/ouzdwtn/';
                     axios.get(url, {
                        params: {
                            client: _this.currentClientProfile.workspace,
                            email: _this.currentClientProfile.email,
                            name: _this.currentClientProfile.name,
                            file: fileToUpload
                        }
                    })
                    .then(function(res) {
                        console.log(res);
                    })
                    .catch(function(err) {
                        console.log(err);
                    })
                    _this.uploadSent = true
                })
                .catch(function(err){
                 console.error(err);
                });
            },
            cleanPrefixes: function (sortedArray) {
              //Get rid of prefixes and capitalize first name
              let nameColumn = sortedArray.map(j => j[0]);
              let prefixRegex = /^(adm|atty|capt|chief|cmdr|col|dr|father|gen|gov|hon|mr|mrs|ms|prof|rev)\.?$/;
              for (let i = 1; i < nameColumn.length; i++) {
                let join = false;
                let names = nameColumn[i].split(' ');
                if (names.length > 1) {
                  if (names[0].trim().toLowerCase().match(prefixRegex)) {
                    names.shift();
                    if (names[0].trim().toLowerCase().match(/^(and|&)$/) && names[1].trim().toLowerCase().match(prefixRegex)) {
                      names.shift();
                      names.shift();
                    }
                join = true;
              }
              //Get rid of first initial
              if (names.length > 2 && names[0].match(/^\w\.?$/)) {
                names.shift();
                join = true;
                  }
              if (join) sortedArray[i][0] = names.join(' ').trim().replace('  ',' ');
                }
                sortedArray[i][0] = sortedArray[i][0].replace(/^\w/, c => c.toUpperCase());
              }
              // console.log(sortedArray);
              return sortedArray;
            },
            sendAuthLink () {
                let urlBase = 'https://api.nthround.io/portal';
                let authLinkUrl = urlBase + '/auth/get-one-week-authlink';
                let client = 'www.staging' //this.currentClientProfile.workspace;
                // let company_name = this.currentClientProfile.company_name;
                let userid = this.currentClientProfile.email.toLowerCase();
                // let clientName = this.currentClientProfile.name;
                userid = userid.trim();
                let _this = this
                // const authUrl = 'https://oxx4gukklc.execute-api.us-east-1.amazonaws.com/presigned-exec/AQICAHjyZmzK8d2PGyyv1Zve93nUW1AyRl9VZtdIMbp8zlT_-QGNIk_itJ0ParpLeJiClnuLAAAA6TCB5gYJKoZIhvcNAQcGoIHYMIHVAgEAMIHPBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDLJN4EKzBbSlwPHNLgIBEICBoZx9i6PosKU9wp1GUNWZtgPYj2t11uVfyRrZ1RClNa0EqWiVFK41WLKBsGCTVjsESkDBoL6jYUZELqDPLi8jQQjXKs5Ml1RheGEqnMq1UMXKpuHTCvbOKaVlNP2NDx7FvD9miEpw6iQMpfzOSkIJZpgVaLQ9sSXQ_d2nkP9pa8wZH1AraLU0zGveE2-sc_FiLKLKxMDS-tYKST-LWoTU2dvK?client=' + client + "&userid=" + encodeURIComponent(userid) + "&io.nthround.env=staging";
                const zapURL = 'https://hooks.zapier.com/hooks/catch/4626966/o5lqm88/'

                axios.get(authLinkUrl, {
                    params: {
                      client: client,
                      userid: userid,
                    }
                })
                .then(function (response) {
                     console.log(response);
                    _this.authLink = 'http://login.nthround.io/?userid=' + userid + '&authlinkID=' + response.data.authorizedLink;
                    _this.currentTask = 5;
                    axios.get(zapURL, {
                        params: {
                            authLink: response.data.authorizedLink,
                            userid: userid,
                        }
                    })
                       .then(res => {
                         console.log(res);
                       })
                       .catch(err => {
                         console.error(err);
                       })
                })
                .catch(err => {
                     console.error(err);
                   })
                
            },
            updateCode () {
                if (this.accessCode.length) {
                    this.complete = true
                } else {
                    this.complete = false
                }
            },
            pay: function () {
              // createToken returns a Promise which resolves in a result object with
              // either a token or an error key.
              // See https://stripe.com/docs/api#tokens for the token object.
              // See https://stripe.com/docs/api#errors for the error object.
              // More general https://stripe.com/docs/stripe.js#stripe-create-token.
              if (this.complete) {
                this.showCardError = false;
                let _this = this

                if (this.accessCode.length) {
                    // Check the code against the db
                    var codesRef = firebase.firestore().collection("codes");
                    codesRef.where("access_code", "==", this.accessCode.toUpperCase())
                    .get()
                    .then(function(querySnapshot) {
                        if(querySnapshot.docs.length || _this.accessCode.toUpperCase() === _this.currentClientProfile.workspace.toUpperCase()) {
                            querySnapshot.forEach(function(doc) {
                                
                                // doc.data() is never undefined for query doc snapshots
                                console.log(doc.id, doc.data());
                                firebase.firestore().collection('clients').doc(_this.$route.params.id).set({code_used: doc.data().access_code},{ merge: true }).then(() => {
                                  // window.analytics.track('Added Payment Method', {});
                                  let url = 'https://hooks.zapier.com/hooks/catch/4626966/om3l2oc/';
                                     axios.get(url, {
                                        params: {
                                            company: _this.currentClientProfile.company_name,
                                            client: _this.currentClientProfile.workspace,
                                            email: _this.currentClientProfile.email,
                                            name: _this.currentClientProfile.name,
                                            code: doc.data().access_code
                                        }
                                    })
                                    .then(function(res) {
                                        console.log(res);
                                        if (_this.currentClientProfile.type == 'selfImport') {
                                            _this.incrementTask()
                                        } else {
                                            _this.sendAuthLink()
                                        }
                                        
                                    })
                                    .catch(function(err) {
                                        console.log(err);
                                    })
                                    
                                });
                                
                            });
                        } else {
                            console.log('No code found: ' + querySnapshot.docs.length)
                            _this.accessCode = ''
                            _this.complete = false
                            _this.codeError = true
                        }
                        
                    })
                    .catch(function(error) {
                        console.log("Error getting documents: ", error);
                    });
                } else {
                    // Create a Stripe token
                  createToken()
                    .then(data => {
                        console.log(data.token)
                        console.log(this.$route.params.id)
                        // this.incrementTask()
                        firebase.firestore().collection('clients').doc(this.$route.params.id).set({token: data.token},{ merge: true }).then(() => {
                              // window.analytics.track('Added Payment Method', {});

                              if (_this.currentClientProfile.type == 'selfImport') {
                                    _this.incrementTask()
                                } else {
                                    _this.sendAuthLink()
                                }
                            });

                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
                }
            },
            createSampleData () {
              let shareholders = [
                { 
                    user_name:"Morry Reddish1",
                    email:"mreddish0@ycombinator.com", 
                    shares: {
                        common: {
                            owned: "7163", 
                            approved: "7163", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data, Advisor"]
                },
                {
                    user_name:"Ddene Aymer",
                    email:"daymer1@hugedomains.com",
                    shares: {
                        common:{
                            owned: "7028", 
                            approved: "7028", 
                            committed:"0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Jana Schellig",
                    email:"jschellig2@yandex.ru",
                    shares: {
                        common: {
                            owned: "7826", 
                            approved: "7826", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data, Advisor"]
                },
                {
                    user_name:"Tawnya Plaxton",
                    email:"tplaxton3@meetup.com",
                    shares: {
                        common: {
                            owned: "5400", 
                            approved: "5400", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Renie Odom",
                    email:"rodom4@rediff.com",
                    shares: {
                        common: {
                            owned: "8645", 
                            approved: "8645", 
                            committed: "0", 
                            permitPurchase: true
                        }, 
                        preferred: {
                            owned: "3500", 
                            approved: "3500", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data, Employee, CEO"]
                },
                {
                    user_name:"Arielle Podbury",
                    email:"apodbury5@wired.com",
                    shares: {
                        common: {
                            owned: "7543", 
                            approved: "7543", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data, Employee"]
                },
                {
                    user_name:"Irvine Alker",
                    email:"ialker6@spotify.com",
                    shares: { 
                        common: {
                            owned: "4913",
                            approved: "4913", 
                            committed: "0", 
                            permitPurchase: true
                        },
                        preferred: {
                            owned: "1533", 
                            approved: "1533", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data, Employee"]
                },
                {
                    user_name:"Herrick Dibb",
                    email:"hdibb7@reddit.com", 
                    shares: {
                        common: {
                            owned: "1308", 
                            approved: "1308", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data, Employee"]
                },
                {
                    user_name:"Goldie Snoddy",
                    email:"gsnoddy8@cbc.ca",
                    shares: {
                        common: {
                            owned: "7331", 
                            approved: "7331", 
                            committed: "0", 
                            permitPurchase: true
                        },
                        preferred: {
                            owned: "369", 
                            approved: "369", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data, Employee"]
                },
                {
                    user_name:"Carol-jean Krienke",
                    email:"ckrienke9@nasa.gov", 
                    shares: {
                        common: {
                            owned: "7994", 
                            approved: "7994", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Adina Berns",
                    email:"abernsa@hud.gov", 
                    shares: {
                        common: {
                            owned: "1154",
                            approved: "1154", 
                            committed: "0", 
                            permitPurchase: true
                        },
                        preferred: {
                            owned: "2324", 
                            approved: "2324", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Wallace Celes",
                    email:"wcelesb@wisc.edu", 
                    shares: {
                        common: {
                            owned: "5912", 
                            approved: "5912", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data, Family"]
                },
                {
                    user_name:"Carri Todhunter",
                    email:"ctodhunterc@forbes.com", 
                    shares: {
                        common: {
                            owned: "3126", 
                            approved: "3126", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data, Family"]
                },
                {
                    user_name:"Selina Swainson",
                    email:"sswainsond@joomla.org", 
                    shares: {
                        common: {
                            owned: "8095", 
                            approved: "8095", 
                            committed: "0", 
                            permitPurchase: true
                        }
                    }, 
                    tags: ["Sample Data"]
                },
                {
                    user_name:"Margalo Garton",
                    email:"mgartone@chronoengine.com", 
                    shares: {}, 
                    tags: ["Sample Data, Family"]
                }
              ];

              let _this = this
              this.currentlyImporting = true
              
              let promises = []

              // function createShareholder (shareholder) {

              // }

              shareholders.forEach(function (shareholder) {

                    // let shareholderObj = ;

                    // console.log(shareholderObj);

                    let postURL = 'https://oxx4gukklc.execute-api.us-east-1.amazonaws.com/presigned-exec/AQICAHjyZmzK8d2PGyyv1Zve93nUW1AyRl9VZtdIMbp8zlT_-QHIC9v54ruZY8YouriDkWEiAAAA2TCB1gYJKoZIhvcNAQcGoIHIMIHFAgEAMIG_BgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDF5_0NPpGSrm-ugVYgIBEICBkVsofShMXmejxucWphcWsu4Br3c8fnkU6-qDdtamrkx778j2U02mpH2uzEFMo84Rai1xaFU2Wko00DwmOk_5IdVy6JMCoZfKfT3_QyZ1tAjVDf6y_kc-MTjT6q4uGlUYmFCTdivz34RoJ_gFsVpLtFZ0d0dLzT4OiqnJpxf1u0kbn6pTqi7pLnBaoh4CBLNPf5A=?io.nthround.env=master'
                    
                    promises.push(
                    axios.post(postURL, {
                        user_name: shareholder.user_name,
                        user_img: 'https://www.gravatar.com/avatar/952b80295294f41ee6612f062c878ab3?s=80&d=retro',
                        userid: shareholder.email.toLowerCase(),
                        'io.nthround.env': 'master',
                        ethereum_address: null,
                        clients: {
                            [_this.currentClientProfile.workspace]: {
                                investor: {
                                    complete: false,
                                    entity: false,
                                    income: false,
                                    netWorth: false,
                                    sophisticated: false
                                },
                                joinder: {
                                    complete: false
                                },
                                shares: shareholder.shares,
                                documents: [],
                                tags: shareholder.tags
                            }
                        }
                    })
                        .then(function(res) {
                            console.log(res)
                            let userlistURL = 'https://boz71b8oe5.execute-api.us-east-1.amazonaws.com/Stage/client/addmin?client=' + _this.currentClientProfile.workspace + '&userid=' + _this.currentClientProfile.email + '&userlist=' + shareholder.email;
                    promises.push(
                    axios.post(userlistURL)
                        .then(function(res){
                            console.log(res, 'Added: ' + shareholder.email)
                        })
                        .catch(function(err){
                            console.error(err);
                            _this.uploadError = true
                        })  
                    )    
                        })
                        .catch(function(err){
                            console.error(err);
                            _this.uploadError = true
                        })
                    )
                });

                axios.all(promises)
                    .then(function(values) {
                        console.log(values);
                        _this.currentlyImporting = false;
                        _this.sendAuthLink()
                        _this.currentTask = 5;
                    })
                    .catch(function(err) {
                        console.error(err);
                    });

               
            },
            importShareholders (shareholders) {
              // console.log(shareholders);

              // console.log('Share classes: ' + (shareholders[0].length - 2));
              let l = shareholders[0].length;
              let classes = shareholders[0].slice(2, l);
              let _this = this
              console.log('Share classes: ', classes);
              // this.currentlyImporting = true
              let promises = []
              shareholders.forEach(function (shareholder, index) {
                // Start after the header row
                if (index > 0) {
                    let sharesObj = {}
                    console.log(shareholder[1], shareholder.length);

                    classes.forEach(function (i, index) {
                        if (Number(shareholder[index + 2]) > 0) {
                            sharesObj[i] = {
                                owned: shareholder[index + 2],
                                approved: "0",
                                committed: "0",
                                permitPurchase: true 
                            }
                        }
                    });

                    console.log(sharesObj);
                    // 

                    let shareholderObj = {
                        user_name: shareholder[0],
                        user_img: 'https://www.gravatar.com/avatar/952b80295294f41ee6612f062c878ab3?s=80&d=retro',
                        userid: shareholder[1],
                        'io.nthround.env': 'master',
                        ethereum_address: null,
                        clients: {
                            [_this.currentClientProfile.workspace]: {
                                investor: {
                                    complete: false,
                                    entity: false,
                                    income: false,
                                    netWorth: false,
                                    sophisticated: false
                                },
                                joinder: {
                                    complete: false
                                },
                                shares: sharesObj,
                                documents: [],
                                tags: []
                            }
                        }
                    }

                    console.log(shareholderObj);

                    let postURL = 'https://oxx4gukklc.execute-api.us-east-1.amazonaws.com/presigned-exec/AQICAHjyZmzK8d2PGyyv1Zve93nUW1AyRl9VZtdIMbp8zlT_-QHIC9v54ruZY8YouriDkWEiAAAA2TCB1gYJKoZIhvcNAQcGoIHIMIHFAgEAMIG_BgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDF5_0NPpGSrm-ugVYgIBEICBkVsofShMXmejxucWphcWsu4Br3c8fnkU6-qDdtamrkx778j2U02mpH2uzEFMo84Rai1xaFU2Wko00DwmOk_5IdVy6JMCoZfKfT3_QyZ1tAjVDf6y_kc-MTjT6q4uGlUYmFCTdivz34RoJ_gFsVpLtFZ0d0dLzT4OiqnJpxf1u0kbn6pTqi7pLnBaoh4CBLNPf5A=?io.nthround.env=master'
                    promises.push(
                    axios.post(postURL, shareholderObj)
                        .then(function(res) {
                            console.log(res)
                            let userlistURL = 'https://boz71b8oe5.execute-api.us-east-1.amazonaws.com/Stage/client/addmin?client=' + _this.currentClientProfile.workspace + '&userid=' + _this.currentClientProfile.email + '&userlist=' + shareholderObj.userid;
                            axios.post(userlistURL)
                            .then(function(res){
                                console.log(res, 'Added: ' + shareholderObj.userid)
                            })
                            .catch(function(err){
                                console.error(err);
                                _this.uploadError = true
                            })
                        })
                        .catch(function(err){
                            console.error(err);
                            _this.uploadError = true
                        })
                    )

                    axios.all(promises)
                    .then(function(values) {
                        console.log(values);
                        _this.currentlyImporting = false;
                        _this.sendAuthLink()
                        _this.currentTask = 4;
                    })
                    .catch(function(err) {
                        console.error(err);
                    });
                                    
                }
              });
              
              
            },
            transformData (csv) {
              let results = Papa.parse(csv);
              // console.log(results);
              // Trim and shrink data
              let data = results.data;
              for (let i = 0; i < data.length; i++)
                for (let j = 0; j < data[i].length; j++)
                  data[i][j] = data[i][j].trim();
              let goodRows = data.filter(element => element.join("") != "");
              let goodCols = (goodRows[0] || []).map((c, i) => goodRows.some(a => a[i]));
              let array = goodRows.map(a => a.filter((_, i) => goodCols[i]));
              // console.log(array);
              // Size up array (assume rectangular array in CSV)
              // let verifyCount = 0;
              let rowCount = array.length;
              let midCount = Math.floor(rowCount/2);
              let colCount = array[midCount].length;
              // let checkCount = Math.floor(rowCount/20);
              let rowDataCount = new Array(rowCount).fill(0);
              // Set up json and regex
              let colData = {
                "name":{"cols":[colCount],"minRow": 999,"maxRow": 0},
                "email":{"cols":[colCount],"minRow": 999,"maxRow": 0},
                "number":{"cols":[colCount],"minRow": 999,"maxRow": 0}
                }
              let nameRegex = /\b[a-zA-Z ]+(?:([',. &()-][a-zA-Z ])?[a-zA-Z]*)*\b\)?/
              let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
              let numberRegex = /^(\d+(?:[.,]\d+)?(?:[.,]\d+)?\b(?!(?:[.,/]\d+)|(?:\s?%|\s?percent|\s?pct)))$/
              let wierdRegex = /[~!@#$%^&*()_+{}|:"<>?`=[\]\\;'/-]/
              // Count the instances in each column
              for (let j = 0; j < colCount; j++) {
                colData.name.cols[j] = 0;
                colData.email.cols[j] = 0;
                colData.number.cols[j] = 0;
                for (let i = 0; i < rowCount; i++) {
                    let text = array[i][j].trim();
                    let match = text.match(nameRegex);
                    if (match && match[0].length == text.length && !text.match(emailRegex) && text.length < 50) {
                    colData.name.cols[j]++;
                    if (j > 2 && j < colCount-2) console.log("Unexpected Name = " + text);
                    if (i < colData.name.minRow) colData.name.minRow = i;
                    if (i > colData.name.maxRow) colData.name.maxRow = i;
                  }
                  if (text.match(emailRegex)) {
                    colData.email.cols[j]++;
                    if (j == 0 || (j > 4 && j != colCount - 1)) console.log("Unexpected Email = " + text);
                    if (i < colData.email.minRow) colData.email.minRow = i;
                    if (i > colData.email.maxRow) colData.email.maxRow = i;
                  }
                match = text.match(numberRegex);
                if (match && match[0].length == text.length && Number(match[0].replace(/[^.\d]/g, '')) > 0 && !text.match(wierdRegex)) {
                    colData.number.cols[j]++;
                    if (j < 1) {
                        console.log("Unexpected Number = " + text);
                        this.uploadError = true;
                    }
                    if (i < colData.number.minRow) colData.number.minRow = i;
                    if (i > colData.number.maxRow) colData.number.maxRow = i;
                  }
                  if (text != "") rowDataCount[i]++;
                }
              }
              // console.log(colData);

              // If multiple name columns, concatenate
                let highCharCol = -1;
                let lastNameCol = -1;
                let firstNameCol = -1;
                let highCharCount = 0;
                let charCount = new Array(colCount).fill(0);
                let nameCutOff = Math.max(...colData.name.cols) / 2;
                for (let j = 0; j < colCount; j++) {
                    if (colData.name.cols[j] > nameCutOff) {
                        lastNameCol = j;
                        if (firstNameCol < 0) firstNameCol = j;
                        for (let i = 0; i < rowCount; i++) {
                            charCount[j] += array[i][j].trim().length;
                        }
                        if (charCount[j] > highCharCount) {
                            highCharCol = j;
                            highCharCount = charCount[j];
                        }
                    } else {
                        if (lastNameCol > 0) break;
                    }
                }
                if (firstNameCol < lastNameCol) {
                    let concats = lastNameCol - firstNameCol;
                    for (let k = 0; k < concats; k++) {
                        charCount[highCharCol] = 0;
                        let nextCharCol = charCount.indexOf(Math.max(...charCount));
                        for (let i = 0; i < rowCount; i++) {
                            array[i][nextCharCol] = (array[i][nextCharCol] + " " + array[i][highCharCol]).trim();
                        }
                        colData.name.cols[highCharCol] = 0;
                        highCharCol = nextCharCol;
                    }
                }

              //Identify columns and determine starting and finishing rows
              let nameCol = colData.name.cols.reduce((jMax, x, j, arr) => x > arr[jMax] ? j : jMax, 0);
              let emailCol = colData.email.cols.reduce((jMax, x, j, arr) => x > arr[jMax] ? j : jMax, 0);
              let nameCount = colData.name.cols[nameCol];
              let emailCount = colData.email.cols[emailCol];
              // let numberCount = Math.max(...colData.number.cols);
              let startRow = Math.max(colData.name.minRow,colData.number.minRow);
              let finishRow = Math.min(colData.name.maxRow,colData.number.maxRow);
              // handle no or screwy number columns
              if (startRow > finishRow) {
                startRow = colData.name.minRow;
                finishRow = colData.name.maxRow;
              }
              if (emailCount > nameCount / 2) {
                //use emails too
                startRow = Math.max(startRow,colData.email.minRow);
                finishRow = Math.min(finishRow,colData.email.maxRow);
              }
              let minDataCount = rowDataCount.reduce((a,b) => a + b, 0)  / rowDataCount.length / 3;
              let maxDataCount = minDataCount * 6;  //5;
              for (let i = startRow; i <= finishRow; i++) {
                if (i < (finishRow / 2) && (rowDataCount[i] < minDataCount || rowDataCount[i] > maxDataCount))
                  startRow = i + 1;
                if (i > (finishRow / 2) && (rowDataCount[i] < minDataCount || rowDataCount[i] > maxDataCount)) {
                  //if (array[i][nameCol] != "") {    // assumes contiguous columns of names ??
                  //  finishRow = i - 1;
                  //} else {  //handle row sub-headings and/or sub-totals
                    rowDataCount.splice(i,1);
                    array.splice(i,1);
                    finishRow--;
                    i++;
                  //}
                } else if (i > (finishRow * 0.8) && (JSON.stringify(array[i]).toLowerCase().indexOf("total") >= 0))
                  finishRow = i - 1;
              }
              //Score data to remove any numeric rows that correspond to subtotals and totals
              let MIN_NUM_COUNT = Math.min(4, finishRow - startRow);
              let headings = new Array(colCount).fill("");
              let totals = new Array(colCount).fill(0);
              for (let j = 0; j < colCount; j++) {
                for (let i = 0; i < startRow; i++) {
                  headings[j] += array[i][j] + " ";
                }
                headings[j] = headings[j].replace(/ +(?= )|0 /g,' ').trim();
                for (let i = startRow; i <= finishRow; i++)
                  if (colData.number.cols[j] > MIN_NUM_COUNT && array[i][j].length > 0) {
                    if (!array[i][j].match(numberRegex)) {
                      array[i][j] = "";
                      continue;
                    }
                    array[i][j] = parseFloat(array[i][j].replace(/[^.\d]/g, ''));
                    totals[j] += array[i][j];
                  }
              }
              // console.log(headings);
              // console.log(totals);
              let goodFacts = new Array(colCount).fill(0);
              for (let j =0; j < colCount; j++) {
                let heading = headings[j].toLowerCase();
                if (colData.number.cols[j] < MIN_NUM_COUNT) goodFacts[j] = -10;
                if (heading.match(/options|rsu|sar|warrant/)) goodFacts[j]++;
                if (heading.match(/common|preferred|series/)) goodFacts[j]++;
                if (heading.match(/fully diluted|outstanding|ownership|\/sh|per/)) goodFacts[j]--;
                if (heading.match(/total|price/)) goodFacts[j]--;
                if (totals[j] > Math.max(...totals)) goodFacts[j]--;  // <-- This is delicate
                if (totals[j] <= Math.min(...totals)) goodFacts[j]++;
                if (totals[j] < 10) goodFacts[j]--;
              }


              // deal with no emails, add dummy column
                if (nameCol == emailCol || emailCount < nameCount / 10) {
                    headings[colCount] = "DummyEmail";
                    emailCol = colCount;
                    colCount++;
                }

              //Create final array
              array.length = finishRow + 1;
              for (let i = 0; i < startRow; i++)
                array.shift();
              //console.log(array);
              let sortOrder = new Array(colCount).fill(999);
              sortOrder[0] = nameCol;
              sortOrder[1] = emailCol;
              let nextCol = 2;
              // Very delicate for keeping Class columns and ignoring Total columns (VET vs Dropps vs Greenfields)
              let okayFacts = Math.max(...goodFacts) - 1; // <-- This is delicate
              for (let j = 0; j < colCount; j++) {
                if (colData.number.cols[j] > MIN_NUM_COUNT && (goodFacts[j] >= 0 || goodFacts[j] >= okayFacts)) {
                  sortOrder[nextCol] = j;
                  nextCol++;
                }
              }
              //Rearrange the columns and create headings
              let sortedHeadings = new Array(nextCol).fill("");
              let headingRegex = /options?|rsu|sar|warrants?|common|preferred|series|\d+/
              let sortedArray = Array(array.length).fill(null).map(() => Array(nextCol));
              let badEmailArray = [];
              for(let j = 0; j < colCount; j++) {
                if (sortOrder[j] == 999) continue;
                for (let i = 0; i < array.length; i++) {
                  sortedArray[i][j] = array[i][sortOrder[j]];
                }       
                let heading = headings[sortOrder[j]];
                //console.log(heading);
                let words = heading.split(' ');
                heading = "";
                for (let k = 0; k < words.length; k++) {
                  if (words[k].toLowerCase().match(headingRegex) || words[k].length == 1)
                    heading += words[k] + " ";
                }
                if (heading.trim().length == 0) heading = headings[sortOrder[j]];
                if (heading.trim().length == 0) heading = "Unknown";
                sortedHeadings[j] = heading.trim();
              }

              // Insert new code
              // if (emailCol == 0) {
              //   for (let i = 0; i < sortedArray.length; i++) {
              //     if (sortedArray[i][1] == "") {
              //       sortedArray[i][1] = "no email provided";
              //       this.uploadError = true;
              //     }
              //   }
              // }

              // sortedHeadings[0] = "Name";
              // sortedHeadings[1] = "Email";

                sortedHeadings[0] = "Name";
                if (sortedHeadings[1] != "DummyEmail") {
                    sortedHeadings[1] = "Email";
                }
                sortedArray.unshift(sortedHeadings);
                colCount = sortedHeadings.length;
                // console.log(sortedArray);

              // sortedArray.unshift(sortedHeadings);
              // console.log(sortedArray);
              sortedArray = this.cleanPrefixes(sortedArray);
              //To Do: Clean up the Names column so that entries best conform to Firstname Lastname
              let nameColumn = sortedArray.map(j => j[0]);
              let commaCount = nameColumn.filter(text => text.includes(",")).length;
              //console.log(nameColumn);
              //console.log(commaCount);
              let suffixRegex = /^(L\.?P\.?|L\.?L\.?C\.?|P\.?C\.?|J\.?T\.?|INC\.?|CORP\.?|JTROS\.?|JR\.?|SR\.?|II|III|IV|V|VI)$/;
              if (commaCount / nameColumn.length > 0.5) {
                    for (let i = 1; i < nameColumn.length; i++) {
                      if (nameColumn[i].length > 40) continue;
                      let names = nameColumn[i].split(',');
                      // if (i == 84)
                      //  console.log(names);
                      if (names.length == 1) continue;
                      let lastName = names[0].trim();
                      names.shift();
                      //handle interim suffix
                      if (names.length > 1 && names[0].trim().toUpperCase().match(suffixRegex)) {
                        let suffix = names[0].trim();
                        names.shift();
                        sortedArray[i][0] = (names.join(' ').trim() + " " + lastName + ", " + suffix).replace('  ',' ').trim();
                        continue;
                      }
                      //handle ending suffix
                      let firstNames = names.join().trim().split(' ');
                      if (firstNames.length > 0) {
                        if (firstNames[firstNames.length-1].toUpperCase().match(suffixRegex)) {
                          let suffix = firstNames[firstNames.length-1].trim();
                          firstNames.length = firstNames.length-1;
                          sortedArray[i][0] = (firstNames.join(' ').trim() + " " + lastName + ", " + suffix).replace('  ',' ').trim();
                          continue;
                        }
                      } else if (names[0].toUpperCase().match(suffixRegex)) continue;
                      sortedArray[i][0] = (names.join().trim() + " " + lastName).replace('  ',' ').trim();
                    }
                    // console.log(sortedArray);
                    sortedArray = this.cleanPrefixes(sortedArray);
                }
                // identify missing emails and handle duplicate emails
                badEmailArray = sortedArray.slice(0, 1);
                for (let i = 1; i < sortedArray.length; i++) {
                    if (sortedArray[i][1] == undefined || sortedArray[i][1].length < 6) {
                        sortedArray[i][1] = "no email provided";
                        badEmailArray.push(sortedArray[i]);
                        sortedArray.splice(i, 1);
                        //console.log(badEmailArray[badEmailArray.length-1]);
                        i--;                    
                    } else {
                        for (let j = 0; j < i; j++) {
                            if (sortedArray[i][1] == sortedArray[j][1]) {
                                if (sortedArray[j][0].trim() != sortedArray[i][0].trim())
                                    sortedArray[j][0] = sortedArray[j][0] + " + " + sortedArray[i][0];
                                for (let k = 2; k < colCount; k++) {
                                    let valueI = sortedArray[i][k];
                                    let valueJ = sortedArray[j][k];
                                    if (isNaN(valueI) || isNaN(valueJ)) {
                                        if (!isNaN(valueI)) sortedArray[j][k] = valueI;
                                    } else {
                                        valueJ += valueI;
                                        sortedArray[j][k] = valueJ;
                                    }
                                }
                                sortedArray.splice(i, 1);
                                //console.log(sortedArray[j]);
                                i--;
                                break;
                            }
                        }
                    }
                }

                // Get all numbers back to strings
                for (let j = 1; j < sortedArray.length; j++) {
                    for (let k = 2; k < colCount; k++) {
                        sortedArray[j][k] = sortedArray[j][k].toString();
                    }
                }
                for (let j = 1; j < badEmailArray.length; j++) {
                    for (let k = 2; k < colCount; k++) {
                        badEmailArray[j][k] = badEmailArray[j][k].toString();
                    }
                }

                // Flatten and trim email addresses         
                for (let j = 1; j < sortedArray.length; j++) {
                    sortedArray[j][1] = sortedArray[j][1].trim().toLowerCase();
                }

              console.log(sortedArray)
              if (sortedArray.length > 1) {
                console.log('Good', sortedArray)
                this.shareholders = sortedArray
              } else {
                console.log('Only headings:', sortedArray)
                this.uploadError = true
              }
              // console.log(badEmailArray)
              if (badEmailArray.length > 1) {
                this.badData = badEmailArray;
                console.log('Bad', badEmailArray);
                // this.uploadError = true
              }
              // sortedArray.unshift(sortedHeadings);
              // console.log(sortedArray);
              // console.log(sortedHeadings);
              
              //To Do: Clean up the Names column so that entries best conform to Firstname Lastname
            },
            loadFromFile (file) {
              // const file = ev.target.files[0];
              console.log(file);
              this.currentFile = file;
              let  rABS = typeof FileReader !== 'undefined' && FileReader.prototype && FileReader.prototype.readAsBinaryString;
              const reader = new FileReader();
              // reader.readAsText(file);
              if (rABS) {
                reader.readAsBinaryString(file);
              } else {
                reader.readAsArrayBuffer(file);
              }

              let that = this;
              // let csv = '';
              reader.onload = e => {
                 // csv = e.target.result;
                 // this.transformData(csv);
                 if (e.target.result.length > 1e8) {
                    // alert("File too large for timely process.");
                    // return;
                    this.uploadError = true;
                }

                let results = e.target.result;
                var wb, arr;
                var readtype = {type: rABS ? 'binary' : 'base64' };

                if(!rABS) {
                    // arr = fixdata(results);
                    results = btoa(arr);
                }

                function doit() {
                    try {
                        //sif(useworker) { sheetjsw(results, process_wb, readtype); return; }
                        wb = XLSX.read(results, readtype);
                        //process_wb(wb);
                        console.log("SLSX version = " + XLSX.version)
                        results = XLSX.utils.make_csv(wb.Sheets[wb.SheetNames[0]]);
                        console.log(results);
                        that.transformData(results);
                    } catch(err) { console.log(err); }  //opts.errors.failed(err); }
                }
                let opts = {};
                if (e.target.result.length > 1e8) {
                    opts.errors.large(e.target.result.length, function(e) { 
                        if (e) {
                            doit();
                        } 
                    });
                } else { 
                    doit(); 
                }
                /* END NEW CODE */
              }
            }
        }
    }
</script>