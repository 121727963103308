<template>
	<div id="dashboard" class="container px-4 md:px-20 py-16 bg-gray-200 min-h-screen flex items-center">
			<div class="p-4 bg-white rounded shadow md:max-w-md w-full mx-auto">
				<div v-if="!currentlyCreating">
					<div class="text-center my-4">
						<img src="https://res.cloudinary.com/nthround/image/upload//h_48/v1576255169/nth-logo.svg" class="w-32 mb-2 mx-auto">
						<h1 class="text-2xl mb-4">Welcome to Nth Round.</h1>
					</div>

                    <ValidationObserver v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(createNewClient)">

                            <validation-provider rules="required" v-slot="{ errors }" class="my-2 block">
                                <input v-model.trim="clientForm.name" placeholder="Name" type="text" id="name" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker"/>
                                <span class="text-red-500 text-xs mt-1">{{ errors[0] }}</span>
                            </validation-provider>

                            <validation-provider rules="required|email" v-slot="{ errors }" class="my-2 block">
                                <input v-model.trim="clientForm.email" placeholder="Email" type="text" id="email" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker"/>
                                <span class="text-red-500 text-xs mt-1">{{ errors[0] }}</span>
                            </validation-provider>

                            <validation-provider rules="required" v-slot="{ errors }" class="my-2 block">
                                <input v-model.trim="clientForm.company_name" @input="updateUrl" placeholder="Company Name" type="text" id="coName" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" />
                                <span class="text-red-500 text-xs mt-1">{{ errors[0] }}</span>
                            </validation-provider>

                            <validation-provider rules="required" v-slot="{ errors }" class="my-2 block">
                                <input v-model.trim="clientForm.workspace" placeholder="Workspace" type="text" id="workspace" class="appearance-none border rounded w-full py-2 px-3 text-grey-darker" />
                                <span class="text-red-500 text-xs mt-1">{{ errors[0] }}</span>
                            </validation-provider>

                                <button type="submit" class="w-full bg-gray-800 hover:bg-gray-900 text-white py-2 px-3 rounded mb-2">Create Account</button>
                            
                        </form>
                    </ValidationObserver>
                    <div class="flex justify-between"><p>Have an account? 
                        <router-link :to="{ name: 'Login' }" style="color: #299AA4">Login</router-link></p>
                    </div>
				</div>
				<div v-if="currentlyCreating" class="h-48 flex items-center justify-center">
					<div class="">
						<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve" class="mx-auto">
							<rect x="0" y="13" width="4" height="5" fill="#333">
								<animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite" />
								<animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite" />
							</rect>
							<rect x="10" y="13" width="4" height="5" fill="#333">
								<animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
								<animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
							</rect>
							<rect x="20" y="13" width="4" height="5" fill="#333">
								<animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
								<animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
							</rect>
						</svg>
						<div class="text-gray-600 text-sm text-center mt-4">Creating client...</div>
					</div>
				</div>
			</div>
			
	</div>
</template>

<script>
	const fb = require('../firebaseConfig.js')
    import axios from 'axios'
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
    import { required, email } from 'vee-validate/dist/rules';

    extend('required', {
      ...required,
      message: 'This field is required'
    })
    extend('email', email);

    export default {
        data() {
            return {
                clientForm: {
                   name: '',
                   email: '',
                   company_name: '', 
                   workspace: ''
                },
				currentlyCreating: false
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        computed: {
            isDisabled: function() {
                return !this.disableSaving
            }
        },
        methods: {
			updateUrl () {
				this.clientForm.workspace = this.clientForm.company_name.trim().toLowerCase().replace(/\s/g, '').replace(/[^\w\d]/g, '')
			},
			createPlatformClient (workspace, username, email, company, id) {
                email = encodeURIComponent(email.toLowerCase());
                let url = 'https://boz71b8oe5.execute-api.us-east-1.amazonaws.com/Prod/client/newClientConfig?client=' + workspace + '&userid=' + email + '&user_name=' + username + '&company_name=' + company + '&plan=pro' + '&company_logo=https://res.cloudinary.com/nthround/image/upload//h_48/v1576255169/nth-logo.svg';
					console.log(workspace, username, email, company);
					window.analytics.track('Account Created', {
                        clientName: username,
                        clientEmail: email,
                        workspace: workspace,
                        company_name: company,
                        created_by: 'Customer',
                        created_at: Math.round((new Date()).getTime() / 1000)
                     });
                
                // let _this = this

                axios.post(url)
                    .then(res => {
                        console.log(res);
                        this.currentlyCreating = false;
                        this.$router.push({ path: `/id/${id}` })

                        // ADD NEW CLIENT AND ADMIN TO COGNITO
                        axios.get('https://api.nthround.io/portal/auth/create-client',{
                            params: {
                                client: workspace,
                                userid: email,
                                user_name: username,
                                company: company,
                                plan: 'pro'
                            },
                            //withCredentials: true
                        })
                        .then(function (response) {
                            console.log(response);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                        console.log(res);

                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
			createNewClient () {
                this.currentlyCreating = true
                let workspace = this.clientForm.workspace.trim().toLowerCase().replace(/\s/g, '')
                fb.clientsCollection.add({
                    name: this.clientForm.name,
                    email: this.clientForm.email.toLowerCase(),
                    company_name: this.clientForm.company_name,
                    workspace: workspace,
                    type: 'selfImport'
                }).then(ref => {
                    let id = ref.id
					this.createPlatformClient (workspace, this.clientForm.name, this.clientForm.email, this.clientForm.company_name, id)
                    // this.$router.push({ path: `/s/id/${id}` })
                    // this.createPlatformClient(workspace, this.clientForm.name, this.clientForm.email.toLowerCase(), this.clientForm.company_name, this.clientForm.logo, this.clientForm.type)
                }).catch(err => {
                    console.log(err);
                })
            }
        }
    }
</script>