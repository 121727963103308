import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { sync } from 'vuex-router-sync'
import { store } from './store'
const fb = require('./firebaseConfig.js')
import '@/assets/tailwind.css'

Vue.config.productionTip = false

const unsync = sync(store, router)

// handle page reloads
let app
fb.auth.onAuthStateChanged(user => {
    if (!app) {
		window.user = user
        app = new Vue({
            el: '#app',
            router,
            store,
            render: h => h(App)
        })
    }
})

unsync()